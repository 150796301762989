<template>
    <div>
        <headerlayout class="h"/>
        
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="main" v-else>
            <div class="banner">
                <div class="image">
                    <img :src="collection.cover" alt="">
                </div>
                <div class="dark">
                    <div class="info">
                        <div class="name">{{ collection.name }}</div>
                        <div class="flex">
                            <div class="fle">
                                <div class="label">Owner:</div>
                                <div class="value">
                                    {{ collection.user.firstname }} {{ collection.user.lastname }}
                                </div>
                            </div>
                            <div class="fle">
                                <div class="label">Arts:</div>
                                <div class="value">{{ collection.arts }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu_container">
                <div class="menu">
                    <div class="items">
                        <div class="item" @click="c = 0" :class="[c == 0 ? 'a' : '']">Arts</div>
                        <div class="item" @click="c = 1" :class="[c == 1 ? 'a' : '']">About</div>
                    </div>
                </div>
            </div>
            <div v-if="c == 0">
                <div class="title">
                    Arts
                </div>
                <div class="empty" v-if="arts.length == 0">
                    There are no arts in this collection
                </div>
                <div class="grid" v-else>
                    <product v-for="art in arts" :key="art.art_id" :art="art"/>
                </div>
            </div>
            <div v-if="c == 1">
                <div class="title">About</div>
                <div class="about">
                    {{ collection.about }}
                </div>
            </div>
        </div>
        
        <footerlayout/>
    </div>
</template>

<script>

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

import product from '../components/util/product.vue';

export default {

    components: {
        headerlayout, footerlayout,
        product
    },
    data() {
        return {
            collection: {},
            arts: [],
            loading: true,
            c: 0
        }
    },
    mounted() {
        window.scroll(0, 0)
        let form = new FormData()
        form.append('url', this.$route.params.url)

        this.$http.post('/collection', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.collection = r.collection
                this.arts = r.arts

                this.loading = false
            }
        })
    }
}
</script>

<style scoped>

    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    
    .main {
        padding-bottom: 50px;
        background-color: #f1f1f3;
    }

    .banner {
        height: 300px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .image img {
        width: 100%;
    }
    .dark {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .info {
        max-width: 1100px;
        margin: 0 auto;
        padding: 20px;
        color: white;
    }
    .name {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
    }
    .fle {
        display: flex;
        gap: 5px;
    }
    .label {
        opacity: .8;
    }
    .value {
        font-weight: 600;
    }



    
    
    .menu_container{
        background-color: white;
    }
    .menu {
        border-bottom: 1px solid #ddd;
    }
    .items {
        display: flex;
        max-width: 1100px;
        margin: 0 auto;
    }
    .item {
        padding: 15px 25px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
    }
    .a {
        color: var(--main);
        border-bottom-color: var(--main);
    }


    


    .title {
        max-width: 1100px;
        margin: 0 auto;
        padding: 20px;
        padding-bottom: 0px;

        font-size: 18px;
        font-weight: 600;
    }
    .empty {
        text-align: center;
        padding: 20px;
        font-size: 14px;
        color: gray;
        margin-top: 40px;
    }
    
    .grid {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
        /* padding-top: 0px; */

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 30px;
    }
    
    .about {
        max-width: 1100px;
        margin: 0 auto;
        padding: 20px;
        line-height: 25px;
    }


    
    .loading {
        margin-top: 150px;
        margin-bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }



    @media screen and (max-width: 500px) {
        .banner {
            height: 200px;
        }
    }
</style>