<template>
    <div>
        <headerlayout class="h"/>
        
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="main" v-else>
            <div class="banner">
                <div class="image">
                    <img :src="user.cover"/>
                </div>
                <div class="info">
                    <div class="dp">
                        <img :src="user.dp" alt="">
                    </div>
                    <div class="name">
                        {{user.firstname}} {{user.lastname}}
                        <check :size="'15px'" v-if="user.vip"/>
                    </div>
                    <div class="flex">
                        <div>@{{ user.username }}</div>
                        <div>Joined: {{joined}}</div>
                    </div>
                </div>
            </div>
            <div class="menu_container">
                <div class="menu">
                    <div class="items">
                        <div class="item" @click="c = 0" :class="[c == 0 ? 'a' : '']">Collections</div>
                        <div class="item" @click="c = 1" :class="[c == 1 ? 'a' : '']">Arts</div>
                        <div class="item" @click="c = 2" :class="[c == 2 ? 'a' : '']">About</div>
                    </div>
                </div>
            </div>
            <div v-if="c == 0">
                <div class="title">
                    Collections
                </div>
                <div class="empty" v-if="collections.length == 0">
                    This user has not created any collection yet
                </div>
                <div class="grid" v-else>
                    <collection v-for="collection in collections" :key="collection.collection_id" 
                        :collection="collection"/>
                </div>
            </div>
            <div v-if="c == 1">
                <div class="title">
                    Arts
                </div>
                <div class="empty" v-if="arts.length == 0">
                    This user has added any art yet
                </div>
                <div class="grid" v-else>
                    <product v-for="art in arts" :key="art.art_id" :art="art"/>
                </div>
            </div>
            <div v-if="c == 2">
                <div class="title">About</div>
                <div class="about">
                    {{ user.about }}
                </div>
            </div>
        </div>
        
        <footerlayout/>
    </div>
</template>

<script>

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

import check from '../icons/check.vue'

import collection from '../components/util/collection.vue';
import product from '../components/util/product.vue';


import moment from 'moment';

export default {

    components: {
        headerlayout, footerlayout,
        check,
        collection, product
    },
    data() {
        return {
            loading: true,
            user: {},
            arts: [],
            collections: [],

            joined: '',
            c: 0
        }
    },
    mounted() {
        window.scroll(0, 0)

        let form = new FormData()
        form.append('username', this.$route.params.id)

        this.$http.post('/user', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.user = r.user
                this.arts = r.arts
                this.collections = r.collections
                
                this.joined = moment(this.user.created).format('Do MMM YYYY')

                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
    
    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .main {
        padding-bottom: 50px;
        background-color: #f1f1f3;
    }
    
    .banner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        overflow: hidden;
    }
    .image {
        position: absolute;
        top: 0px;
        left: 0px;

        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .image img {
        width: 100%;
    }

    .info {
        text-align: center;
        margin: 50px 0px;
        position: relative;
        z-index: 2;
    }
    .dp {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .dp img {
        width: 100px;
    }
    .name {
        font-weight: 600;
        font-size: 20px;
        margin-top: 10px;
        color: white;
        
        display: flex;
        justify-content: center;
        gap: 5px;
        fill: white;
    }
    .flex {
        display: flex;
        gap: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #d1d1d1;
    }
    .menu_container {
        background-color: white;
    }
    .menu {
        border-bottom: 1px solid #ddd;
    }
    .items {
        display: flex;
        max-width: 1100px;
        margin: 0 auto;
    }
    .item {
        padding: 15px 25px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
    }
    .a {
        color: var(--main);
        border-bottom-color: var(--main);
    }


    .title {
        max-width: 1100px;
        margin: 0 auto;
        padding: 20px;
        padding-bottom: 0px;

        font-size: 18px;
        font-weight: 600;
    }
    .empty {
        text-align: center;
        padding: 20px;
        font-size: 14px;
        color: gray;
        margin-top: 40px;
    }
    
    .grid {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
        /* padding-top: 0px; */

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 30px;
    }

    .about {
        max-width: 1100px;
        margin: 0 auto;
        padding: 20px;
        line-height: 25px;
    }


    
    .loading {
        margin-top: 150px;
        margin-bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>