<template>
    <div class="checkout">
        <headerlayout class="h"/>

        <div class="main">
            <div class="title">Checkout</div>
            <div class="grid">
                <div class="arts">
                    <div class="art" v-for="art in arts" :key="art.art_id">
                        <div class="image">
                            <img :src="art.image"/>
                        </div>
                        <div class="art_info">
                            <div class="name">{{ art.title }}</div>
                            <div class="flex">
                                <div class="art_label">Category:</div>
                                <div class="value">{{ art.category }}</div>
                            </div>
                            <div class="flex">
                                <div class="art_label">Price:</div>
                                <div class="value">{{ art.price }} {{ art.currency }}</div>
                            </div>
                            <div class="item">
                                <div class="art_label">Owner:</div>
                                <div class="value">{{ art.user.firstname }} {{ art.user.lastname }}</div>
                            </div>
                            <div class="item">
                                <div class="art_label">Description:</div>
                                <div class="value">{{ art.description }}</div>
                            </div>
                            <div class="item">
                                <div class="art_label">About:</div>
                                <div class="value">{{ art.about }}</div>
                            </div>
                            <div class="item meta">
                                <div class="art_label">Meta Data:</div>
                                <div class="flex" v-for="(meta, i) in JSON.parse(art.metas)" :key="i">
                                    <div class="art_label">{{meta.label}}:</div>
                                    <div class="value">{{ meta.value }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text">
                        By purchasing this art, you will recieve the original quality of this art work
                        and you will have full right over this art work. After the purchase, you can use
                        this art for anything. You can also resell this art
                    </div>
                    <div class="input">
                        <div class="label">Total Amount</div>
                        <input type="text" :value="total + ' ETH'" disabled>
                    </div>
                    <div class="input">
                        <div class="label">Payment Method</div>
                        <select >
                            <option value="ETH" selected>ETH</option>
                        </select>
                    </div>
                    <loading :height="'38px'" v-if="loading"/>
                    <div class="btn" v-else @click="proceed()">Proceed</div>
                </div>
            </div>
        </div>

        <errorbox v-if="show_err" :date="date" :order_id="order_id"/>

        <footerlayout/>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitnfts')

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

import loading from '../components/loading.vue';
import errorbox from '../components/popup/error.vue'
export default {

    components: {
        headerlayout, footerlayout,
        loading, errorbox
    },
    mounted() {
        window.scroll(0, 0)

        db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$router.push('/login')
                this.$root.$data.login = false
            } else {
                if (!document.email_verified) {
                    this.$router.push('/verify')
                }
            }

        })

        this.arts = this.$root.$data.cart
        for (let x of this.arts) {
            this.total += x.price
        }

        if (this.arts.length == 0) {
            this.empty_cart()
            this.$router.push('/explore')
        }
    },
    data() {
        return {
            arts: [],
            total: 0,

            order_id: '',
            date: '',

            show_err: false,
            loading: false
        }
    },
    methods: {
        proceed() {
            this.loading = true

            let arts_id = []

            for(let x of this.arts) {
                arts_id.push(x.art_id)
            }

            let form = new FormData
            form.append('arts', JSON.stringify(arts_id))

            this.$http.post('/buy/art', form)
            .then( data => {
                let r = data.data

                if (r.success) {
                    this.$root.$data.cart = []
                    localStorage.setItem('cart', '[]')
                    
                    this.order_id = r.order_id
                    this.date = r.date

                    setTimeout(() => {
                        this.show_err = true
                        this.loading = false
                    }, 8000);
                }
            })
        }
    },
    notifications: {
        empty_cart: {
            type: '',
            title: '',
            message: 'Your cart is currently empty, add art from the marketplace'
        }
    }
}
</script>

<style scoped>


    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .main {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }

    .grid {
        display: grid;
        grid-template-columns: calc(60% - 10px) calc(40% - 10px);
        gap: 20px;
        margin-top: 30px;
    }
    .art {
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 30% calc(70%);
    }
    .image {
        overflow: hidden;
    }
    .image img {
        width: 100%;
        display: block;
    }

    .art_info {
        padding: 20px;
        font-size: 14px;
    }
    .name {
        font-size: 16px;
        font-weight: 600;
    }
    .flex {
        display: flex;
        gap: 5px;
        /* font-size: 14px; */
        margin-top: 15px;
    }
    .art_label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .item {
        margin-top: 15px;
    }
    .item > .value {
        margin-top: 5px;
    }
    .meta .flex {
        margin-top: 5px;
    }


    .text {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        font-size: 14px;
        margin-bottom: 2px;
        font-weight: 500;
    }
    input, select {
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        padding: 10px;
        text-align: center;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }



    @media screen and (max-width: 850px) {
        .grid, .art {
            grid-template-columns: 100%;
        }
    }
</style>