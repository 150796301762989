<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Edit Collection</div>

            <div class="main">
                <div class="cover">
                    <img :src="cover_src"/>
                    <div class="click pos" @click="click_cover()">Change Photo</div>
                </div>
                <div class="input">
                    <div class="label">Name</div>
                    <input type="text" placeholder="Enter collection name" v-model="collection_name">
                </div>
            </div>
            <div class="foot">
                <loading :height="'34px'" v-if="saving"/>
                <div class="btn" v-else @click="save()">Save</div>
            </div>
        </div>
        
        <input type="file" accept="image/*" class="hidden" 
                ref="cover" @change="handlecover($event)"/>
    </div>
</template>

<script>
import imageCompression from 'browser-image-compression';
import loading from '../../loading.vue'

export default {
    props: ['cover', 'name', 'collection_id'],
    components: {
        loading
    },
    data() {
        return {
            cover_src: '',
            cover_file: null,
            is_cover: false,

            collection_name: '',

            saving: false
        }
    },
    mounted() {
        this.cover_src = this.cover
        this.collection_name = this.name
    },
    methods: {
        close() {
            if (this.saving) { return }
            this.$emit('close')
        },
        click_cover() {
            this.$refs.cover.click()
        },
        handlecover(event) {
            this.uploading = true
            for (let i = 0; i < event.target.files.length; i++) {
                let mediaType = event.target.files[i].type
                if (mediaType.includes('image')) {
                    const picture = event.target.files[i]
                    
                    this.compress(picture, 'cover_file')
                    const reader = new FileReader()
                    reader.readAsDataURL(picture)
                    reader.onload = event => {
                        this.cover_src = event.target.result
                        this.is_cover = true

                        this.$refs['cover'].value = ''
                    }
                }
            }
            this.uploading = false
        },
        compress(file, store) {
            let name = file.name
            
            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(file, options).then( res => {
                this[store] = new File([res], name)
                this.uploading = false
            })
        },
        save() {
            if (this.collection_name == '') { this.empty(); return }
            this.saving = true

            let form = new FormData()
            form.append('name', this.collection_name)
            form.append('collection_id', this.collection_id)
            form.append('is_cover', this.is_cover ? '1' : '0')

            if (this.is_cover) {
                form.append('cover', this.cover_file)
            }

            this.$http.post('/user/collection/edit', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Collection name cannot be empty'
        }
    }
}
</script>

<style scoped>

.popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        /* height: 80vh; */
        overflow: auto;
        border-radius: 5px;

        position: relative;
        z-index: 4;
    }
    .title {
        padding: 20px;
        font-weight: 600;
        border-bottom: 1px solid #ddd;
    }

    .main {
        padding: 20px;
    }
    
    
    .cover {
        position: relative;

        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .cover img {
        width: 100%;
    }
    .click {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 5px 20px;
        font-size: 12px;
        position: absolute;
        text-align: center;
        cursor: pointer;
    }
    .input {
        margin-top: 20px;
        position: relative
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
    }
    input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }





    .foot {
        border-top: 1px solid #ddd;
        padding: 20px;
    }
    .btn {
        padding: 8px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }

    
    
    input[type=file] {
        visibility: hidden;
        display: none;
    }
</style>