<template>
    <div class="wallet">
        <div class="title">Wallet</div>
        <div class="no">
            {{balance}} ETH
        </div>
        <div class="flex">
            <div class="click" @click="show_add = true">Add ETH</div>
            <div class="click" @click="show_remove = true">Remove ETH</div>
        </div>
        <div class="text">Transactions</div>
        
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <transaction v-for="tx in transactions" :tx="tx" :key="tx.id"/>
        </div>


        <add_eth v-if="show_add" :user_id="user.user_id"
            @close="show_add = false" @added="add($event)"/>

        <remove_eth v-if="show_remove" :user_id="user.user_id"
            @close="show_remove = false" @removed="remove_eth($event)"/>
    </div>
</template>

<script>
import transaction from './transaction.vue'

import add_eth from './add_eth.vue'
import remove_eth from './remove_eth.vue'

export default {
    props: ['user'],
    components: {
        add_eth, remove_eth,
        transaction
    },
    data() {
        return {
            show_add: false,
            show_remove: false,
            balance: 0,

            loading: true,
            transactions: []
        }
    },
    mounted() {
        this.balance = parseFloat(this.user.balance)

        this.fetch_transactions()
    },
    methods: {
        add(amount) {
            this.balance += amount
            this.added()
            this.show_add = false
            this.fetch_transactions()
        },
        remove_eth(amount) {
            this.balance -= amount
            this.added()
            this.show_remove = false
            this.fetch_transactions()
        },
        fetch_transactions() {
            this.loading = true
            
            let form = new FormData()
            form.append('user_id', this.user.user_id)

            this.$http.post('/admin/transactions', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.transactions = r.transactions
                    this.loading = false
                }
            })
        }
    },
    notifications: {
        added: {
            type: 'success',
            title: '',
            message: 'User balance Updated'
        }
    }
}
</script>

<style scoped>
    
    .wallet {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        /* margin-bottom: 15px; */
    }
    .no {
        padding: 30px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    .flex {
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    .click {
        background-color: var(--main);
        padding: 8px 30px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
    }

    .text {
        /* margin-top: 20px; */
        margin: 20px 0px;
        font-weight: 500;
    }

    


    .loading {
        margin: 50px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>