<template>
    <div class="search">
        
        <div class="main">
            <div class="box">
                <div class="icon" @click="back()">
                    <back :size="'20px'" :style="{'height': '20px'}"/>
                </div>
                <input type="text" placeholder="Search Arts and Collections" ref="input" v-model="text"
                    @input="typing()" @keyup.enter="search()">
            </div>
            <div>
                <div class="info" v-if="not_enter">
                    <div>
                        <search :size="'40px'"/>
                        <div class="text">Input collection name or art name and press enter</div>
                    </div>
                </div>
                <div v-else>
                    <div class="loading" v-if="loading">
                        <div class="spin"></div>
                    </div>
                    <div v-else>
                        <div class="title">Arts</div>
                        <div class="empty" v-if="arts.length == 0">
                            There are currently no art with the keyword {{ text }}
                        </div>
                        <div class="grid">
                            <product v-for="art in arts" :key="art.art_id" :art="art"/>
                        </div>

                        <div class="title">Collections</div>
                        
                        <div class="empty" v-if="collections.length == 0">
                            There are currently no collection with the keyword {{ text }}
                        </div>
                        <div class="grid">
                            <collection v-for="collection in collections" :key="collection.collection_id"
                                :collection="collection"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footerlayout/>
    </div>
</template>

<script>
import back from '../icons/back.vue';
import search from '../icons/search.vue';

import product from '../components/util/product.vue';
import collection from '../components/util/collection.vue';

import footerlayout from '../components/util/footer.vue';
export default {
    components: {
        back, search,
        product, collection,
        footerlayout
    },
    data() {
        return {
            text: '',
            collections: [],
            arts: [],

            not_enter: true,
            loading: false
        }
    },
    mounted() {
        this.$refs.input.focus()
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        typing() {
            this.not_enter = true
            this.loading = false
        },
        search() {
            if (this.text == '') { return }

            this.not_enter = false
            this.loading = true

            let form = new FormData()
            form.append('name', this.text)

            this.$http.post('/search', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.arts = r.arts
                    this.collections = r.collections

                    this.loading = false
                }
            })
        }
    },
}
</script>

<style scoped>

    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .main {
        min-height: 100vh;
        max-width: 1100px;
        padding: 20px;
        margin: 0 auto;

        display: grid;
        grid-template-rows: max-content auto;
    }

    .box {
        border: 1px solid #ddd;
        border-radius: 5px;
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        gap: 10px;
        padding-left: 15px;
        overflow: hidden;
    }
    .icon {
        background-color: #f1f1f3;
        width: 35px;
        height: 35px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    input {
        padding: 15px;
        padding-left: 5px;
        width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
    }

    .info {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        fill: grey;
        color: gray;
        font-size: 14px;
        font-weight: 500;
    }
    .info .text {
        margin-top: 10px;
    }


    .title {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 500;
    }
    .empty {
        font-size: 14px;
        color: gray;
        /* text-align: center; */
        margin: 30px 0px;
        margin-bottom: 60px;
    }
    .grid {
        margin: 30px 0px;
        margin-bottom: 60px;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 30px;
    }

    
    
    .loading {
        margin-top: 150px;
        margin-bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

</style>