<template>
    <div class="verify">
        <headerlayout class="h"/>
        
        <div class="main">
            <div class="box">
                <div class="title">Welcome Onboard</div>
                <div class="text">Please complete your details below</div>
                
                <div class="input">
                    <div class="label" v-if="firstname !== ''">Firstname</div>
                    <input type="text" placeholder="Enter your firstname" v-model="firstname">
                </div>
                
                <div class="input">
                    <div class="label" v-if="lastname !== ''">Lastname</div>
                    <input type="text" placeholder="Enter your lastname" v-model="lastname">
                </div>
                
                <div class="input">
                    <div class="label" v-if="code !== ''">Email Verification Code</div>
                    <div class="grid">
                        <input type="text" 
                            :placeholder="'Enter the code sent to ' + $root.$data.email" v-model="code">
                        <div class="click" @click="get_code()">Get Code</div>
                    </div>
                </div>
                <!-- <div class="input">
                    <div class="label">Wallet address (optional)</div>
                    <div class="wallet" @click="connect_wallet()">
                        <img src="../assets/images/metamask.svg"/>
                        <div class="t" v-if="wallet == ''">Click to connect wallet</div>
                        <div class="t" v-else>{{ wallet }}</div>
                    </div>
                </div> -->

                <loading v-if="loading" :height="'39px'"/>
                <div class="btn" v-else @click="verify()">Proceed</div>
            </div>
        </div>
        <footerlayout/>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitnfts')

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

import loading from '../components/loading.vue';

export default {

    components: {
        headerlayout, footerlayout,
        loading
    },
    mounted() {

        db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$router.push('/')
            }
        })
    },
    data() {
        return {
            firstname: '',
            lastname: '',
            code: '',
            wallet: '',

            loading: false
        }
    },
    methods: {
        get_code() {
            this.$http.post('/get_email_otp')
            this.email_sent()
        },
        async connect_wallet() {
            if (typeof window.ethereum !== 'undefined') {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                this.wallet = accounts[0];

                this.wallet_gotten()
            } else {
                this.no_metamask()
            }
        },
        verify() {
            if (this.firstname == '' || this.lastname == '' || this.code == '') {
                this.empty()
            }

            this.loading = true

            let form = new FormData()
            form.append('firstname', this.firstname)
            form.append('lastname', this.lastname)
            form.append('code', this.code)
            form.append('wallet', this.wallet)

            this.$http.post('/verify', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    
                    db.collection('auth').doc({ id: 1 }).update({ 'email_verified': true })
                    .then(() => {
                        this.$router.push('/dashboard')
                    })
                } else {
                    if (r.verified) {
                        db.collection('auth').doc({ id: 1 }).update({ 'email_verified': true })
                        this.$router.push('/dashboard')
                    }
                    if (r.code) {
                        this.wrong_code()
                    }
                }

                this.loading = false
            })
        }
    },
    notifications: {
        email_sent: {
            type: 'success',
            title: '',
            message: 'Email verification code sent'
        },
        empty: {
            type: 'warn',
            title: '',
            message: 'Some field are empty'
        },
        no_metamask: {
            type: 'warn',
            title: '',
            message: 'Metamask not detected'
        },
        wrong_code: {
            type: 'warn',
            title: '',
            message: 'Incorrect verification code'
        },
        wallet_gotten: {
            type: 'info',
            title: '',
            message: 'Wallet address fetched'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Email address verified'
        }
    }
}
</script>

<style scoped>


    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .main {
        height: 100vh;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f3;
    }

    .box {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        width: 400px;
        padding: 25px;
        border-radius: 10px;
    }
    .title {
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }
    .text {
        text-align: center;
        margin-bottom: 30px;
        font-size: 14px;
    }
    .input {
        margin-bottom: 30px;
        outline: none;
        position: relative;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        background-color: white;
        font-weight: 600;
        padding: 0px 5px;
        font-size: 14px;
    }
    input {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        border: none;
        outline: none;
    }

    .grid {
        display: grid;
        align-items: center;
        grid-template-columns: auto max-content;
    }
    .click {
        padding: 10px;
        color: var(--main);
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
    }

    .wallet {
        padding: 15px 15px;
        display: flex;
        align-items: center;
        gap: 15px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        color: var(--main);
    }
    .wallet img {
        width: 20px;
    }


    .btn {
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        font-weight: 600;
    }

</style>