import Vue from 'vue'
import VueNotifications from 'vue-notifications'
import 'jquery'
import toastr from 'toastr'// https://github.com/CodeSeven/toastr
import 'toastr/build/toastr.min.css'

function toast ({title, message, type, timeout}) {
    if (type === VueNotifications.types.warn) type = 'warning'

    // toastr.options.closeButton = true;
    toastr.options.showMethod = 'slideDown';
    toastr.options.hideMethod = 'slideUp';
    toastr.options.closeMethod = 'slideUp';
    toastr.options.positionClass = 'toast-bottom-center';

    return toastr[type](message, title, {timeOut: timeout})
}

const options = {
    success: toast,
    error: toast,
    info: toast,
    warn: toast
}

Vue.use(VueNotifications, options)