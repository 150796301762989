<template>
    <div class="sale">
        <div class="flex" @click="show_main = !show_main">
            <div class="icon" :class="[show_main ? 'rot' : '']">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="fle">
                <div class="label">Date:</div>
                <div class="value">{{ sale.date }}</div>
            </div>
            <div class="fle">
                <div class="label">Sale Id:</div>
                <div class="value">{{ sale.sale_id }}</div>
            </div>
        </div>
        <div v-if="show_main">

            <div class="main">
                <div class="image">
                    <img :src="sale.art.image"/>
                </div>
                <div class="info">
                    <div class="title">{{ sale.art.title }}</div>
                    <div class="f">
                        <div class="label">Price:</div>
                        <div class="value">{{ sale.art.price }} {{ sale.art.currency }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Art:</div>
                        <router-link :to="'/art/' + sale.art_id">
                            <div class="value click">{{ sale.art_id }}</div>
                        </router-link>
                    </div>
                    <div class="f">
                        <div class="label">Seller:</div>
                        <div class="value">{{ sale.seller.email }} </div>
                    </div>
                    <div class="f">
                        <div class="label">Messages:</div>
                        <div class="value">{{ sale.messages }}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="foot">
                <div class="btn" @click="show_message = true">Message</div>
            </div> -->

        </div>
        
        <message :sale_id="sale.sale_id" v-if="show_message" 
            @close="show_message = false"/>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow.vue';
import message from './message.vue'

export default {
    components: {
        arrow, message
    },
    props: ['sale'],
    data() {
        return {
            show_main: false,
            show_message: false
        }
    }
}
</script>

<style scoped>

    .sale {
        background-color: white;
        margin-top: 20px;
        border-radius: 5px;
        overflow: hidden;
    }
    .flex {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 15px;
        overflow: hidden;
    }
    .fle {
        display: flex;
        gap: 5px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .click {
        font-weight: 600;
        color: var(--main);
    }
    .icon {
        background-color: var(--main);
        fill: white;
        height: 40px;
        width: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg);
    }


    
    

    .main {
        border-top: 1px solid #ddd;
        display: grid;
        grid-template-columns: 30% 70%;
        height: 181px;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .image img {
        width: 100%;
        display: block;
    }
    .info {
        padding: 15px;
    }
    .f {
        display: flex;
        margin-top: 15px;
        font-size: 14px;
        gap: 10px;
    }
    .title {
        font-weight: 600;
    }


    .foot {
        padding: 20px;
        border-top: 1px solid #ddd;
    }
    .foot .text {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .btn {
        font-size: 14px;
        font-weight: 500;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        cursor: pointer;
    }
</style>