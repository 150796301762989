<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Message</div>
            <div class="main">

                <div class="loading" v-if="loading">
                    <div class="spin"></div>
                </div>
                <div class="messages">
                    <div class="message" v-for="message in messages" :key="message.id"
                        :class="[message.sender == 'system' ? 'system' : '']">
                        <div class="text">{{ message.text }}</div>
                        <div class="date">{{ message.date }}</div>
                    </div>
                </div>
            </div>
            <div class="foot">
                <textarea placeholder="Enter message" v-model="text"></textarea>
                
                <loading :height="'38px'" v-if="sending"/>
                <div class="btn" v-else @click="send()">Send</div>
            </div>
        </div>
    </div>
</template>

<script>
import loading from '../../loading.vue';
export default {
    components: {
        loading
    },
    props: ['sale_id'],
    data() {
        return {
            loading: true,
            sending: false,
            messages: [],
            text: '',

            interval: null
        }
    },
    mounted() {
        this.fetch_messages()

        this.interval = setInterval(() => {
            this.fetch_messages()
        }, 5000);
    },
    beforeDestroy () {
        clearInterval(this.interval)
    },
    methods: {
        close() {
            if (this.sending) { return }
            this.$emit('close')
        },
        fetch_messages() {

            let form = new FormData()
            form.append('sale_id', this.sale_id)

            this.$http.post('/admin/sale/messages', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.messages = r.messages

                    this.loading = false
                }
            })

        },
        send() {
            if (this.text == '') {
                this.empty(); return
            }

            this.sending = true

            let form = new FormData()
            form.append('sale_id', this.sale_id)
            form.append('message', this.text)

            this.$http.post('/admin/sale/message', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.messages.push({
                        'sender': 'system',
                        'text': this.text,
                        'date': r.date
                    })

                    this.text = ''
                    this.$emit('new_message')
                }
                this.sending = false
            })
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        border-radius: 5px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        padding: 15px;
        border-bottom: 1px solid #ddd;
    }

    .main {
        padding: 15px;
        height: 50vh;
        overflow: auto;
    }
    .message {
        padding: 10px;
        margin-bottom: 15px;
        max-width: 80%;
        background-color: #f1f1f3;
        border-radius: 10px;
        border-bottom-left-radius: 0px;
        font-size: 14px;
        line-height: 20px;
    }
    .system {
        margin-left: auto;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 0px;
        background-color: white;
        border: 1px solid #ddd;
    }
    .date {
        font-size: 12px;
        text-align: right;
        margin-top: 2px;
        color: gray;
    }




    .foot {
        padding: 15px;
        border-top: 1px solid #ddd;
    }
    textarea {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 5px;
    }
    .btn {
        padding: 10px;
        border-radius: 5px;
        font-weight: 500;
        background-color: var(--main);
        font-size: 14px;
        color: white;
        text-align: center;
        cursor: pointer;
    }

    .loading {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>