<template>
    <div class="box">
        <div class="title">Collections ({{no}})</div>
        <!-- <div class="no">{{no}}</div> -->

        <div v-if="collections.length == 0" class="empty">
            You have not create a collection, create a collection now to start managing your arts
        </div>
        <div v-else>
            <div class="flex" v-for="collection in collections" :key="collection.collection_id">
                <div class="round">
                    <img :src="collection.cover"/>
                </div>
                <div>
                    <div class="name">{{collection.name}}</div>
                    <div class="fle">
                        <div class="f">
                            <div class="label">Arts:</div>
                            <div class="value">{{collection.arts}}</div>
                        </div>
                        <div class="f">
                            <div class="label">Created:</div>
                            <div class="value">{{collection.created}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">
            <router-link to="/dashboard/collections">
                <div class="btn">View All</div>
            </router-link>
            <router-link to="/dashboard/collections">
                <div class="btn">Create</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['collections', 'no'],
    mounted() {
        for(let i = 0; i < this.collections.length; i++) {
            this.collections[i].created = moment(this.collections[i].created).fromNow()
        }
    }
}
</script>

<style scoped>
    .box {
        background-color: white;
        border-radius: 5px;
        height: fit-content;
    }
    .title {
        padding: 20px;
        font-size: 18px;
        font-weight: 600;
    }
    .no {
        margin: 50px 0px;
        font-weight: 500;
        font-size: 25px;
        text-align: center;
    }


    .empty {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 50px 20px;
        text-align: center;
        font-size: 12px;
        color: gray;
    }

    .flex {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 5px 20px;
        border-bottom: 1px solid #ddd;
    }
    .round {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background-color: #f1f1f3;
    }
    .round img {
        width: 70px;
    }

    .name {
        font-weight: 600;
    }
    .fle {
        display: flex;
        gap: 20px;
    }
    .f {
        display: flex;
        gap: 10px;
        font-size: 14px;
    }
    .value {
        font-weight: 500;
    }

    .grid {
        padding: 20px;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .btn {
        padding: 10px;
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
    }
</style>