<template>
    <div class="marketplace">
        <headerlayout class="h"/>

        <div class="main">
            <div class="title">Explore NFT'S</div>
            
            <div class="flex">
                <div class="c" :class="[category == 'all' ? 'a' : '']" 
                    @click="category = 'all'">All</div>
                    
                <div class="c" :class="[category == 'Top' ? 'a' : '']" 
                    @click="category = 'Top'">Top Sellers</div>

                <div class="c" :class="[category == 'Digital Art' ? 'a' : '']" 
                    @click="category = 'Digital Art'">Digital Art</div>

                <div class="c" :class="[category == 'Painting' ? 'a' : '']" 
                    @click="category = 'Painting'">Painting</div>

                <div class="c" :class="[category == 'Drawing' ? 'a' : '']" 
                    @click="category = 'Drawing'">Drawing</div>

                <div class="c" :class="[category == 'Photography' ? 'a' : '']" 
                    @click="category = 'Photography'">Photography</div>

                <div class="c" :class="[category == 'Animation' ? 'a' : '']" 
                    @click="category = 'Animation'">Animation</div>

                <div class="c" :class="[category == 'Graphics Design' ? 'a' : '']" 
                    @click="category = 'Graphics Design'">Graphic Design</div>
            </div>

            
            <div class="loading" v-if="arts_loading">
                <div class="spin"></div>
            </div>
            <div class="grid" v-else>
                <product v-for="art in arts" :key="art.art_id" :art="art"/>
            </div>
        </div>

        <footerlayout/>
    </div>
</template>

<script>

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

import product from '../components/util/product.vue';

export default {

    components: {
        headerlayout, footerlayout,
        product
    },
    data() {
        return {
            category: 'all',
            arts_loading: true,
            arts: []
        }
    },
    mounted() {
        window.scroll(0, 0)
        this.fetch_arts()
    },
    watch: {
        category() {
            this.fetch_arts()
        }
    },
    methods: {
        
        fetch_arts() {
            this.arts_loading = true

            let form = new FormData()
            form.append('category', this.category)
            form.append('where', 'new')
            
            this.$http.post('/browse/arts', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.arts = r.arts
                    this.arts_loading = false
                }
            })
        },
    }
}
</script>

<style scoped>
    .marketplace {
        background-color: #f1f1f3;
    }
    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .main {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }

    
    .flex {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        overflow: auto;
    }
    .c {
        padding: 8px 20px;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        white-space: nowrap;
        background-color: white;
    }
    .a {
        background-color: var(--main);
        color: white;
        border-color: transparent;
    }

    
    
    .loading {
        margin-top: 150px;
        margin-bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .grid {
        max-width: 1100px;
        margin: 30px auto;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 30px;
    }
</style>