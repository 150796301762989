<template>
    <div class="cart_popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="head">
                <div class="title">Cart</div>
                <div class="head_flex">
                    <div class="head_label">Items:</div>
                    <div class="head_value">{{ cart.length }}</div>
                </div>
            </div>
            <div class="empty" v-if="cart.length == 0">
                Cart is currently empty, add arts to cart from the Marketplace
            </div>
            <div class="arts" v-else>
                <div class="art" v-for="(art, i) in cart" :key="art.art_id">
                    <div class="art_flex">
                        <div class="image">
                            <img :src="art.image"/>
                        </div>
                        <div class="info">
                            <div class="title">{{ art.title }}</div>
                            <div class="flex">
                                <div class="label">Price:</div>
                                <div class="value">{{ art.price }} {{ art.currency }}</div>
                            </div>
                            <div class="flex">
                                <div class="label">Category:</div>
                                <div class="value">{{ art.category }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom_flex">
                        <router-link :to="'/art/' + art.art_id">
                            <div class="click">View art</div>
                        </router-link>
                        <div class="click" @click="remove_from_cart(i)">Remove from cart</div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="btn" @click="remove_all()">Remove All</div>
                <div class="btn" @click="checkout()">Checkout</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        cart() {
            return this.$root.$data.cart
        }
    },

    methods: {
        remove_from_cart(i) {
            let cart = this.$root.$data.cart
            cart.splice(i, 1)
            localStorage.setItem('cart', JSON.stringify(cart))

            this.item_removed()
        },
        remove_all() {
            this.$root.$data.cart = []
            localStorage.setItem('cart', '[]')

            this.removed_all()
        },
        checkout() {
            if (this.cart.length == 0) {
                this.empty_cart(); return
            }

            if (!this.$root.$data.login) { this.login(); return }
            this.$router.push('/checkout')
        }
    },
    notifications: {
        item_removed: {
            'type': 'success',
            'title': '',
            'message': 'Item removed from cart'
        },
        removed_all: {
            type: 'success',
            title: '',
            message: 'All items removed from cart'
        },
        empty_cart: {
            type: 'warn',
            title: '',
            message: 'Your cart is currently empty'
        },
        login: {
            type: 'warn',
            title: '',
            message: 'You are required to login first'
        }
    }
}
</script>

<style scoped>

    .cart_popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        border-radius: 5px;

        position: relative;
        z-index: 4;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #ddd;
    }
    .title {
        font-weight: 600;
    }
    .head_flex {
        display: flex;
        font-size: 14px;
        gap: 5px;
    }
    .head_label {
        color: gray;
    }
    .head_value {
        font-weight: 500;
    }

    .empty {
        padding: 50px 20px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: gray;
    }

    .arts {
        max-height: 70vh;
        overflow: auto;
        padding: 20px;
        padding-top: 0px;
    }
    .art {
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-top: 20px;
    }
    .art_flex {
        display: grid;
        grid-template-columns: 30% auto;
    }
    .image {
        height: 110px;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f3;
    }
    .image img {
        width: 100%;
    }
    .info {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
    }
    .flex {
        display: flex;
        margin-top: 4px;
        font-size: 14px;
        gap: 10px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .bottom_flex {
        display: flex;
        justify-content: space-around;
        border-top: 1px solid #ddd;
    }
    .click {
        padding: 10px 20px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: var(--main);
        cursor: pointer;
    }


    .bottom {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;
        padding: 20px;
        border-top: 1px solid #ddd;
    }
    .btn {
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        padding: 8px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }
</style>