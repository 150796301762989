<template>
    <div class="mobile">
        
        <router-link to="/dashboard">
            <div class="item" :class="[c == 'dashboard' ? 'a' : '']">
                <dashboard :size="'18px'"/>
                Dashboard
            </div>
        </router-link>
        <router-link to="/dashboard/collections">
            <div class="item" :class="[c == 'collections' ? 'a' : '']">
                <collection :size="'18px'"/>
                Collections
            </div>
        </router-link>

        <router-link to="/dashboard/sales">
            <div class="item" :class="[c == 'sales' ? 'a' : '']">
                <sales :size="'18px'"/>
                Sales
            </div>
        </router-link>

        <router-link to="/dashboard/orders">
            <div class="item" :class="[c == 'orders' ? 'a' : '']">
                <orders :size="'18px'"/>
                Orders
            </div>
        </router-link>

        <router-link to="/dashboard/settings">
            <div class="item" :class="[c == 'settings' ? 'a' : '']">
                <settings :size="'18px'"/>
                Settings
            </div>
        </router-link>
    </div>
</template>

<script>
import dashboard from '../../icons/dashboard.vue';
import sales from '../../icons/sales.vue'
import orders from '../../icons/orders.vue'
import collection from '../../icons/collection.vue';
import settings from '../../icons/settings.vue';

export default {

    components: {
        dashboard, sales, orders, collection, settings
    },
    computed: {
        c() {
            return this.$route.name
        }
    }
}
</script>

<style scoped>
    .mobile {
        display: flex;
        align-items: center;
        justify-content: space-around;
        box-shadow: rgba(100, 100, 111, 0.2) 0px -7px 29px 0px;
    }

    .item {
        fill: #686868;
        text-align: center;
        padding: 8px 0px;
        font-weight: 600;
        font-size: 10px;
        cursor: pointer;
    }

    .a {
        color: var(--main);
        fill: var(--main);
    }
</style>