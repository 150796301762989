<template>
    <div class="collection">
        <div class="flex" @click="show_main = !show_main">

            <div class="icon" :class="[show_main ? 'rot' : '']">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="fle">
                <div class="label">Name:</div>
                <div class="value">{{ collection.name }}</div>
            </div>
            <div class="fle">
                <div class="label">Arts:</div>
                <div class="value">{{ collection.arts }}</div>
            </div>
            <div class="fle">
                <div class="label">Sales:</div>
                <div class="value">{{ collection.sales }}</div>
            </div>
        </div>
        <div class="main" v-if="show_main">
            <div class="banner">
                <div class="image">
                    <img :src="collection.cover"/>
                </div>
                <div class="edit" @click="show_edit = true">Edit</div>
                <div class="foot">
                    <div class="name">{{ collection.name }}</div>
                    <div class="foot_flex">
                        <div class="foot_fle">
                            <div class="foot_label">Created:</div>
                            <div class="foot_value">{{ created }}</div>
                        </div>
                        <div class="foot_fle">
                            <div class="foot_label">Arts:</div>
                            <div class="foot_value">{{ collection.arts }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="menu">
                <div class="item" :class="[c == 'arts' ? 'a' : '']" 
                    @click="c = 'arts'"> Arts </div>
                <div class="item" :class="[c == 'about' ? 'a' : '']" 
                    @click="c = 'about'"> About </div>
            </div>

            <div class="container" v-if="c == 'arts'">
                <div class="head">
                    <div class="title">Arts</div>
                    <div class="btn" @click="show_add = true">Add</div>
                </div>
                
                <div class="loading" v-if="loading">
                    <div class="spin"></div>
                </div>
                <div class="arts" v-else>
                    <art v-for="art in arts" :key="art.art_id" :art="art"/>
                </div>
            </div>

            <div class="container" v-if="c == 'about'">
                <div class="head">
                    <div class="title">About</div>
                    <div class="btn">Edit</div>
                </div>
                <div class="about_text">
                    {{ collection.about }}</div>
            </div>
        </div>

        <add v-if="show_add" :collection_id="collection.collection_id"
            @close="show_add = false" @new_art="new_art($event)"/>

        <edit v-if="show_edit" @close="show_edit = false"
            :cover="collection.cover" :name="collection.name" :collection_id="collection.collection_id"/>
            
    </div>
</template>

<script>
import moment from 'moment';

import arrow from '../../../icons/arrow.vue';

import edit from './edit.vue';
import add from './add.vue';
import art from './art.vue';


export default {
    props: ['collection'],
    components: {
        arrow,
        edit, add, art
    },
    mounted() {
        this.created = moment(this.collection.created).format('Do MMM YYYY')
        
        let form = new FormData()
        form.append('collection_id', this.collection.collection_id)

        this.$http.post('/user/collection/arts', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.arts = r.arts

                this.loading = false
            }
        })
    },
    data() {
        return {
            created: '',
            show_main: false,
            show_edit: false,
            show_add: false,

            c: 'arts',
            arts: [],
            loading: true
        }
    },
    methods: {
        new_art(event) {
            this.arts.unshift(event)
            this.collection.arts++

            this.show_add = false
        }
    }
}
</script>

<style scoped>
    .collection {
        background-color: white;
        margin-top: 20px;
        border-radius: 5px;
        overflow: hidden;
    }
    .flex {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 15px;
        overflow: hidden;
        /* padding: 0px 20px; */
    }
    .fle {
        display: flex;
        gap: 5px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .icon {
        background-color: var(--main);
        fill: white;
        height: 40px;
        width: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg);
    }


    .banner {
        position: relative;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
    }
    .image {
        width: 100%;
    }
    .image img {
        width: 100%;
    }
    .edit {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: white;
        padding: 5px 20px;
        border-radius: 5px;
        color: var(--main);
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }
    .foot {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 20px;
    }
    .name {
        font-size: 18px;
        font-weight: 700;
    }
    .foot_flex {
        display: flex;
        gap: 15px;
    }
    .foot_fle {
        display: flex;
        gap: 5px;
        font-size: 12px;
        margin-top: 2px;
    }
    .foot_label {
        opacity: 0.8;
    }
    .foot_value {
        font-weight: 500;
    }


    .menu {
        display: flex;
        border-bottom: 1px solid #ddd;
    }
    .item {
        padding: 10px 20px;
        border-bottom: 2px solid transparent;
        font-size: 14px;
        cursor: pointer;
        transition: .2s;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
        font-weight: 500;
    }



    .container {
        padding: 20px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
    }
    .btn {
        padding: 5px 30px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        background-color: var(--main);
        color: white;
    }




    .about_text {
        margin-top: 15px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
    }


    
    
    .loading {
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }




    @media screen and (max-width: 750px) {
        .flex {
            gap: 10px
        }
    }
</style>