<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 96 960 960">
            <path d="M652 640q25 0 44.5-19.5T716 575q0-25-19.5-44T652 512q-25 0-44.5 19T588 575q0 26 19.5 
            45.5T652 640ZM538 763q-34 0-54-20t-20-53V463q0-34 20-53.5t54-19.5h269q34 0 54 19.5t20 53.5v227q0 
            33-20 53t-54 20H538ZM180 936q-23 0-41.5-18T120 876V276q0-23 18.5-41.5T180 216h600q24 0 42 
            18.5t18 41.5v54H538q-58 0-96 38t-38 95v227q0 57 38 95t96 38h302v53q0 24-18 42t-42 18H180Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>