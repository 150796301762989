<template>
    <div>
        <div class="footer">
            <div class="item">
                <div class="logo">
                    BitNfts
                </div>
                <div class="text">
                    Discover Your Next Masterpiece: It's time to Invest in NFTs
                </div>
            </div>
                <div class="item">
                    <div class="title">Marketplace</div>

                    <router-link to="/explore">
                        <div class="list">ALl NFTs</div>
                    </router-link>
                    <router-link to="/explore">
                        <div class="list">Digital Art</div>
                    </router-link>
                    <router-link to="/explore">
                        <div class="list">Painting</div>
                    </router-link>
                    <router-link to="/explore">
                        <div class="list">Drawings</div>
                    </router-link>
                    <router-link to="/explore">
                        <div class="list">Photography</div>
                    </router-link>
                    <router-link to="/explore">
                        <div class="list">Animation</div>
                    </router-link>
                    <router-link to="/explore">
                        <div class="list">Graphic Design</div>
                    </router-link>
                </div>
            <div class="item">
                <div class="title">My Account</div>

                <div v-if="$root.$data.login">
                    <router-link to="/dashboard">
                        <div class="list">Dashboard</div>
                    </router-link>
                    <router-link to="/dashboard/collections">
                        <div class="list">Collections</div>
                    </router-link>
                    <router-link to="/dashboard/sales">
                        <div class="list">Sales</div>
                    </router-link>
                    <router-link to="/dashboard/orders">
                        <div class="list">Orders</div>
                    </router-link>
                    <div class="list">Cart</div>
                </div>
                <div v-else>
                    <router-link to="/login">
                        <div class="list">Login</div>
                    </router-link>
                    <router-link to="/signup">
                        <div class="list">Sign up</div>
                    </router-link>
                    <router-link to="/forgot_pass">
                        <div class="list">Forgot Password</div>
                    </router-link>
                    <div class="list">Cart</div>
                </div>
            </div>
            <div class="item">
                <div class="title">Resources</div>

                <!-- <div class="list">Stat</div> -->
                
                <router-link to="/about">
                    <div class="list">About</div>
                </router-link>
                <!-- <div class="list">Learn</div> -->
                <div class="list" @click="contact()">Help Center</div>
                <div class="list" @click="contact()">Contact Us</div>
                
                <router-link to="/terms">
                    <div class="list">Terms</div>
                </router-link>
            </div>
        </div>
        <div class="foot">© 2024 BitNfts</div>
    </div>
</template>

<script>
export default {
    methods: {
        contact() {
            this.contact_noty()
        }
    },
    notifications: {
        contact_noty: {
            type: 'info',
            title: '',
            message: 'We are online, use the live chat to message us'
        }
    }
}
</script>

<style scoped>
    .footer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        justify-content: space-between;
        gap: 20px;
        padding: 30px;
        padding-top: 50px;
        padding-bottom: 50px;
        box-sizing: border-box;
        background-color: rgb(2, 12, 10);
        color: white;
        font-size: 14px;
    }
    .item {
        margin-bottom: 30px;
    }

    .logo {
        font-weight: 700;
        font-size: 23px;
    }
    .text {
        margin-top: 20px;
    }

    .title {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .list {
        margin-bottom: 5px;
        cursor: pointer;
    }
    .foot {
        text-align: center;
        padding: 20px;
        font-weight: 600;
        background-color: var(--main);
        color: white;
    }
</style>