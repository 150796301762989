<template>
    <div class="collections">
        <div class="head">
            <div class="title">Collections</div>
            <div class="btn" @click="show_create = true">Create</div>
        </div>

        
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <collection v-for="collection in collections" :key="collection.collection_id" 
                :collection="collection"/>
        </div>

        <div class="empty" v-if="!loading && collections.length == 0">
            You have not created any collection, create a collection now to start managing your arts
        </div>

        <create v-if="show_create" @close="show_create = false"/>
        
    </div>
</template>

<script>
import create from '../../components/dashboard/collections/create.vue'
import collection from '../../components/dashboard/collections/collection.vue'

export default {
    components: {
        create, collection
    },
    data() {
        return {
            collections: [],
            loading: true,
            show_create: false
        }
    },
    mounted() {
        this.$http.post('/user/collections')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.collections = r.collections

                this.loading = false
            }
        })
    },
    methods: {

    }
}
</script>

<style scoped>

    .collections {
        padding: 20px;
        background-color: #f1f1f3;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-weight: 600;
        font-size: 18px;
    }
    .btn {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }

    
    
    .empty {
        padding: 20px;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 400px;
        text-align: center;
        font-size: 14px;
        color: gray;
    }
    .loading {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>