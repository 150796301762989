<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Create Collection</div>
            <div class="upload">
                <div class="image" v-if="is_image">
                    <div class="remove" @click="remove_img()">
                        <close :size="'30px'"/>
                    </div>
                    <img :src="image_src"/>
                </div>
                <div @click="upload()" class="uploader" v-else>
                    <div>
                        <div class="upload_title">Add cover image</div>
                        <div class="upload_small">Leave blank to use default image</div>
                    </div>
                </div>
            </div>
            <div class="input">
                <div class="label">Name</div>
                <input type="text" placeholder="Enter collection name" v-model="name">
            </div>
            <div class="input">
                <div class="label">Url</div>
                <input type="text" placeholder="Enter a url for your collection" v-model="url">
                <div class="note">bitnfts.org/collection/{{ url }}</div>
            </div>
            <div class="input">
                <div class="label">About</div>
                <textarea placeholder="Say something about your collection" v-model="about"></textarea>
            </div>

            <loading :height="'34px'" v-if="loading"/>
            <div class="btn" v-else @click="create()">Create</div>


            <input type="file" accept="image/*" multiple class="hidden" 
                ref="image" @change="handleupload($event)"/>
        </div>
    </div>
</template>

<script>
import imageCompression from 'browser-image-compression';
import loading from '../../loading.vue'
import close from '../../../icons/close.vue'
export default {
    components: {
        loading, close
    },
    data() {
        return {
            uploading: false,
            image_file: null,
            image_src: '',
            is_image: false,
    
            name: '',
            url: '',
            about: '',
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        upload() {
            if (this.uploading) {
                this.up(); return
            }
            this.$refs['image'].click()
        },
        handleupload(event) {
            this.uploading = true
            for (let i = 0; i < event.target.files.length; i++) {
                let mediaType = event.target.files[i].type
                if (mediaType.includes('image')) {
                    const picture = event.target.files[i]
                    
                    this.compress(picture)
                    const reader = new FileReader()
                    reader.readAsDataURL(picture)
                    reader.onload = event => {
                        this.image_src = event.target.result
                        this.is_image = true

                        this.$refs['image'].value = ''
                    }
                }
            }
            this.uploading = false
        },
        compress(file) {
            let name = file.name
            
            const options = {
                maxSizeMB: 0.03,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(file, options).then( res => {
                this.image_file = new File([res], name)
                this.uploading = false
            })
        },
        remove_img() {
            this.is_image = false
            this.image_file = null
            this.image_src = ''
        },
        create() {
            if (this.name == '' || this.url == '' || this.about == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('url', this.url)
            form.append('about', this.about)
            form.append('is_image', this.is_image ? '1' : '0')
            if (this.is_image) {
                form.append('image', this.image_file)
            }

            this.$http.post('/user/collection/create', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()

                    setTimeout(() => {
                        this.$router.go()
                    }, 200);
                } else {
                    if (r.url) {
                        this.url_err()
                    }
                }

                this.loading = false
            })
        }
    },
    notifications: {
        
        up: {
            type: 'warn',
            title: '',
            message: 'please wait while your image upload'
        },
        empty: {
            type: 'warn',
            title: '',
            message: 'Please make sure all field are not empty'
        },
        url_err: {
            type: 'warn',
            title: '',
            message: 'The url you entered has been taken'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Collection created'
        }
    }

}
</script>

<style scoped>
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 300px;
        border-radius: 5px;
        padding: 20px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .upload {
        background-color: #f1f1f3;
        margin-bottom: 20px;

        height: 120px;

        border-radius: 5px;
        overflow: hidden;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .remove {
        fill: var(--main);
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
    }
    .image img {
        width: 100%;
    }
    .uploader {
        height: 120px;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .upload_title {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
    }
    .upload_small {
        text-align: center;
        color: gray;
        font-size: 10px;
    }

    .input {
        margin-bottom: 20px;
        position: relative
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
    }
    input, textarea {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .note {
        font-weight: 500;
        font-size: 12px;
        text-align: right;

        position: absolute;
        right: 0px;

    }
    .btn {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }



    
    
    input[type=file] {
        visibility: hidden;
        display: none;
    }
</style>