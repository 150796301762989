<template>
    <div class="users">
        <div class="title">Users</div>

        <div class="input">
            <input type="text" v-model="text" 
            placeholder="Search by username or email" @input="search()">
        </div>
        
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <user v-for="user in users" :key="user.user_id" :user="user"/>
        </div>
    </div>
</template>

<script>
import user from '../../components/admin/users/user.vue'
export default {
    components: {
        user
    },
    data() {
        return {
            loading: false,
            all: [],
            users: [],

            text: ''
        }
    },
    mounted() {
        this.$http.post('/admin/users')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.all = r.users
                this.users = r.users
            }
        })
    },
    methods: {
        search() {
            let new_users = []

            for (let x of this.all) {
                let username = x.username.toLowerCase()
                let email = x.email.toLowerCase()

                let text = this.text.toLowerCase()

                if (username.includes(text)) {
                    new_users.push(x)
                } else {
                    if (email.includes(text)) {
                        new_users.push(x)
                    }
                }

                this.users = new_users
            }
        }
    }
}
</script>

<style scoped>
    .users {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
    }

    input {
        padding: 12px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;
    }

</style>