<template>
    <div class="art">
        <headerlayout class="h"/>

        <div class="main">

            <div class="loading" v-if="art_loading">
                <div class="spin"></div>
            </div>
            <div v-else class="grid">
                <div>
                    <div class="title">{{ art.title }}</div>
                    <div class="image">
                        <img :src="art.image"/>
                    </div>
                    <div class="item">
                        <div class="label">Title</div>
                        <div class="value">{{ art.title }}</div>
                    </div>
                    <div class="item">
                        <div class="label">Description</div>
                        <div class="value">{{ art.description }}</div>
                    </div>
                    <div class="item">
                        <div class="label">About</div>
                        <div class="value">{{ art.about }}</div>
                    </div>
                    <div class="item">
                        <div class="label">Category</div>
                        <div class="value">{{ art.category }}</div>
                    </div>
                    <div class="item">
                        <div class="label">Owner</div>
                        
                        <router-link :to="'/user/' + art.user.username">
                            <div class="user">
                                <div class="dp">
                                    <img :src="art.user.dp"/>
                                </div>
                                <div>
                                    <div class="name">{{art.user.firstname}} {{art.user.lastname}}</div>
                                    <div class="flex">
                                        <div class="f">
                                            <div class="la">Arts:</div>
                                            <div class="v">{{art.user.arts}}</div>
                                        </div>
                                        <div class="f">
                                            <div class="label">Collections:</div>
                                            <div class="v">{{art.user.collections}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile">Profile</div>
                            </div>
                        </router-link>

                    </div>
                </div>
                <div>
                    <div class="title">Buy this art</div>
                    <div class="btn up" v-if="art.sold">
                        This art has been sold
                    </div>
                    <div v-else>
                        <div class="input">
                            <div class="l">Price</div>
                            <input type="text" disabled :value="art.price + ' ETH'">
                        </div>
                        <div class="btn" @click="buy()">Buy</div>
                    </div>
                </div>
            </div>
        </div>

        <footerlayout/>
    </div>
</template>

<script>

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

export default {
    components: {
        headerlayout, footerlayout,
    },
    data() {
        return {
            art_loading: true,
            art: {},
            in_cart: false
        }
    },
    mounted() {
        window.scroll(0, 0)
        let form = new FormData()
        form.append('art_id', this.$route.params.id)

        this.$http.post('/art', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.art = r.art
                this.art_loading = false

                
                let cart = this.$root.$data.cart

                for (let x of cart) {
                    if (x.art_id == this.art.art_id) { 
                        this.in_cart = true
                    }
                }
            }
        })
    },
    methods: {
        buy() {
            if (!this.$root.$data.login) { this.login(); return }
            if (this.art.user.username == this.$root.$data.username) { this.owned(); return }

            if (!this.in_cart) {
                this.$root.$data.cart.push(this.art)
                this.in_cart = true

                localStorage.setItem('cart', JSON.stringify(this.$root.$data.cart))
                this.added()
            }
            this.$router.push('/checkout')
        }
    },
    notifications: {
        owned: {
            type: 'warn',
            title: '',
            message: "You can't buy your own art"
        },
        added: {
            type: 'success',
            title: '',
            message: 'Art has been added to cart'
        },
        login: {
            type: 'warn',
            title: '',
            message: 'You are required to login first'
        }
    }

}
</script>

<style scoped>


    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .main {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
    }
    
    .grid {
        display: grid;
        grid-template-columns: calc(70% - 10px) calc(30% - 10px);
        gap: 20px;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
    }
    .image {
        border-radius: 5px;
        overflow: hidden;
        margin-top: 20px;
    }
    .image img {
        width: 100%;
        display: block;
    }
    .item {
        margin-top: 20px;
    }
    .label {
        color: gray;
        font-size: 14px;
    }
    .value {
        font-size: 18px;
        font-weight: 500;
        margin-top: 4px;
        line-height: 25px;
    }
    
    .user {
        padding: 10px 0px;
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;
    }
    .dp {
        width: 30px;
        height: 30px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .dp img {
        width: 40px;
    }
    .name {
        font-weight: 600;
        font-size: 14px;
    }
    .flex {
        display: flex;
        gap: 10px;
    }
    .f {
        display: flex;
        gap: 5px;
        font-size: 12px;
    }
    .v {
        font-weight: 500;
    }
    .profile {
        margin-left: auto;
        font-size: 12px;
        font-weight: 500;
        color: white;
        background-color: var(--main);
        padding: 5px 10px;
        border-radius: 5px;
    }
    

    
    .input {
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
    }
    .l {
        font-size: 14px;
        margin-bottom: 2px;
        font-weight: 500;
    }
    input, select {
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .up {
        margin-top: 20px;
    }
    .btn {
        padding: 10px;
        text-align: center;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }
    
    
    .loading {
        margin-top: 150px;
        margin-bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    
    @media screen and (max-width: 850px) {
        .grid {
            grid-template-columns: 100%;
        }
    }
</style>