<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Add Art</div>
            <div class="upload">
                <div class="image" v-if="is_image">
                    <div class="remove" @click="remove_img()">
                        <close :size="'30px'"/>
                    </div>
                    <img :src="image_src"/>
                </div>
                <div @click="upload()" class="uploader" v-else>
                    <div>
                        <div class="upload_title">Upload Art</div>
                        <div class="upload_small">click here to upload art image</div>
                    </div>
                </div>
            </div>
            <div class="input">
                <div class="label">Title</div>
                <input type="text" placeholder="Enter art title" v-model="title">
            </div>
            <div class="input">
                <div class="label">Description</div>
                <textarea placeholder="Describe this art" v-model="description"></textarea>
            </div>
            <div class="input">
                <div class="label">About</div>
                <textarea placeholder="What's this art about?" v-model="about"></textarea>
            </div>
            <div class="input">
                <div class="label">Category</div>
                <select v-model="category">
                    <option value="" selected disabled>Select Category</option>
                    <option value="Digital Art">Digital Art</option>
                    <option value="Painting">Painting</option>
                    <option value="Drawing">Drawing</option>
                    <option value="Photography">Photography</option>
                    <option value="Animation">Animation</option>
                    <option value="Graphics Design">Graphics Design</option>
                </select>
            </div>
            <div class="input">
                <div class="label">Price</div>
                <div class="flex">
                    <input type="number" placeholder="Enter art price" v-model="price">
                    <select>
                        <option value="ETH" selected>ETH</option>
                    </select>
                </div>
            </div>
            <div class="input">
                <div class="f">
                    <div class="label">Meta Data (optional)</div>
                    <div class="click" @click="add_meta()">Add more</div>
                </div>
                <div class="flex up" v-for="(meta, index) in metas" :key="index">
                    <input type="text" placeholder="Label" v-model="meta.label">
                    <input type="text" placeholder="Value" v-model="meta.value">
                </div>
            </div>
            <loading :height="'34px'" v-if="loading"/>
            <div class="btn" @click="add()" v-else>Submit</div>
        </div>
        

        <input type="file" accept="image/*" multiple class="hidden" 
                ref="image" @change="handleupload($event)"/>
    </div>
</template>

<script>
import close from '../../../icons/close.vue'
import loading from '../../loading.vue'

export default {
    components: {
        close, loading
    },
    props: ['collection_id'],
    data() {
        return {
            loading: false,

            uploading: false,
            is_image: false,
            image_src: '',
            image_file: null,

            title: '',
            description: '',
            about: '',
            category: '',
            price: '',

            metas: [
                {
                    'label': '',
                    'value': ''
                },
                {
                    'label': '',
                    'value': ''
                }
            ]
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        add_meta() {
            this.metas.push({
                'label': '',
                'value': ''
            })
        },
        upload() {
            if (this.uploading) {
                this.up(); return
            }
            this.$refs['image'].click()
        },
        handleupload(event) {
            this.uploading = true
            for (let i = 0; i < event.target.files.length; i++) {
                let mediaType = event.target.files[i].type
                if (mediaType.includes('image')) {
                    const picture = event.target.files[i]
                    this.image_file = picture
                    
                    const reader = new FileReader()
                    reader.readAsDataURL(picture)
                    reader.onload = event => {
                        this.image_src = event.target.result
                        this.is_image = true

                        this.$refs['image'].value = ''
                    }
                }
            }
            this.uploading = false
        },
        remove_img() {
            this.is_image = false
            this.image_file = null
            this.image_src = ''
        },

        add() {
            if ( !this.is_image ) {
                this.image_err(); return
            }
            if (this.title == '' || this.description == '' || this.about == '' || this.category == '' ||
                this.price == '') {
                    this.empty(); return
                }
            
            this.loading = true

            let form = new FormData()
            form.append('title', this.title)
            form.append('description', this.description)
            form.append('about', this.about)
            form.append('category', this.category)
            form.append('price', this.price)
            form.append('image', this.image)
            form.append('metas', JSON.stringify(this.metas))
            form.append('collection_id', this.collection_id)
            form.append('image', this.image_file)

            this.$http.post('/user/collection/art/create', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.success()
                    
                    let art = {
                        'created': r.created,
                        'title': this.title,
                        'description': this.description,
                        'about': this.about,
                        'category': this.category,
                        'price': this.price,
                        'currency': 'ETH',
                        'image': r.image,
                        'metas': JSON.stringify('metas'),
                        'status': 'Good',
                        'sold': false,

                        'art_id': r.art_id,
                        'user_id': r.user_id,
                        'collection_id': r.collection_id
                    }

                    this.$emit('new_art', art)
                }
            })
        }
    },
    notifications: {
        up: {
            type: 'warn',
            title: '',
            message: 'please wait while your image upload'
        },
        empty: {
            type: 'warn',
            title: '',
            message: 'Please make sure all field are not empty'
        },
        image_err: {
            type: 'warn',
            title: '',
            message: 'Make sure your art image is attached'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Your art has been uploaded successfully'
        }
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        height: 80vh;
        overflow: auto;
        border-radius: 5px;
        padding: 20px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        margin-bottom: 20px;
    }

    
    .upload {
        background-color: #f1f1f3;
        margin-bottom: 20px;

        /* height: 120px; */

        border-radius: 5px;
        overflow: hidden;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .remove {
        fill: var(--main);
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
    }
    .image img {
        width: 100%;
    }
    .uploader {
        height: 120px;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .upload_title {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
    }
    .upload_small {
        text-align: center;
        color: gray;
        font-size: 10px;
    }

    .input {
        margin-bottom: 20px;
        position: relative
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
    }
    input, textarea, select {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .flex {
        display: flex;
        gap: 20px;
    }
    .f {
        display: flex;
        justify-content: space-between;
        margin-bottom: -15px;
    }
    .up {
        margin-top: 20px;
    }
    .click {
        font-weight: 500;
        font-size: 14px;
        color: var(--main);
        cursor: pointer;
    }
    
    .btn {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }



    
    
    input[type=file] {
        visibility: hidden;
        display: none;
    }
</style>