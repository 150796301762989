<template>
    <div class="user">
        
        <div class="flex" @click="show_main = !show_main">
            <div class="icon" :class="[show_main ? 'rot' : '']">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="fle">
                <div class="value">{{ user.username }}</div>
                <div class="value">-</div>
                <div class="value">{{ user.email }}</div>
            </div>
        </div>
        <div v-if="show_main">
            <div class="main">
                <div class="menu">
                    <div class="item" :class="[c == 0 ? 'a' : '']" @click="c = 0">Details</div>
                    <div class="item" :class="[c == 1 ? 'a' : '']" @click="c = 1">Wallet</div>
                </div>

                <div v-if="c == 0">
                    <detailsVue :user="user"/>
                </div>
                <div v-if="c == 1">
                    <wallet :user="user"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow.vue';
import detailsVue from './details.vue';
import wallet from './wallet.vue';


export default {
    props: ['user'],
    components: {
        arrow,
        detailsVue, wallet
    },
    data() {
        return {
            show_main: false,
            c: 0
        }
    }
}
</script>

<style scoped>

    .user {
        background-color: white;
        margin-top: 20px;
        border-radius: 5px;
        overflow: hidden;
    }
    .flex {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 15px;
        overflow: hidden;
    }
    .fle {
        display: flex;
        gap: 5px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .click {
        font-weight: 600;
        color: var(--main);
    }
    .icon {
        background-color: var(--main);
        fill: white;
        height: 40px;
        width: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg);
    }



    .main {
        border-top: 1px solid #ddd;
    }
    .menu {
        display: flex;
        padding: 0px 20px;
        border-bottom: 1px solid #ddd;
    }
    .item {
        padding: 8px 20px;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
    }

</style>