<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Add Wallet Address</div>
            <div class="small">Metamask not detected, enter your wallet address manually</div>
            <input type="text" v-model="address" placeholder="Enter wallet">
            <!-- <textarea v-model="phrase" placeholder="Enter your 12 seed phrase (optional)"></textarea> -->
            <div class="btn" @click="add()">Add</div>
        </div>
    </div>
</template>

<script>
import WAValidator from 'wallet-address-validator'
export default {
    data() {
        return {
            address: '',
            phrase: ''
        }
    },
    methods: {
        add() {
            let valid = WAValidator.validate(this.address, 'ETH')
            if (valid) {
                this.$emit('address', { 
                    'wallet': this.address,
                    'phrase': this.phrase
                })
            } else {
                this.invalid()
            }
        }
    },
    notifications: {
        invalid: {
            type: 'warn',
            title: '',
            message: 'Please enter a valid ETH wallet address'
        }
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        border-radius: 5px;

        position: relative;
        z-index: 4;
        padding: 20px;
    }

    .title {
        font-size: 18px;
        font-weight: 500;
    }
    .small {
        font-size: 14px;
        color: gray;
        margin-bottom: 20px;
    }
    input, textarea {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .btn {
        padding: 8px;
        font-size: 14px;
        border-radius: 5px;
        background-color: var(--main);
        text-align: center;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
</style>