<template>
    <div class="sales">

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <banner :balance="balance" :wallet="wallet"/>

            <div class="title">Recent Sales</div>

            <sale v-for="sale in sales" :key="sale.sale_art" :sale="sale"/>
                
            <div class="empty" v-if="!loading && sales.length == 0">
                You have not made any sales yet, keep promoting your arts
            </div>
        </div>

    </div>
</template>

<script>
import banner from '../../components/dashboard/sales/banner.vue'
import sale from '../../components/dashboard/sales/sale.vue'

export default {
    components: {
        banner, sale
    },
    data() {
        return {
            loading: true,
            sales: [],
            balance: '',
            wallet: ''
        }
    },
    mounted() {
        this.$http.post('/user/sales')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.sales = r.sales
                this.balance = r.balance
                this.wallet = r.wallet
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
    .sales {
        padding: 20px;
    }

    .title {
        font-weight: 600;
        font-size: 18px;
        margin-top: 20px;
    }
    .empty {
        color: gray;
        text-align: center;
        margin-top: 70px;
        font-size: 14px;
    }




    .loading {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>