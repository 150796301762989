<template>
    <div class="login">
        <router-link to="/login">
            <div class="click">Login</div>
        </router-link>
        <router-link to="/signup">
            <div class="click">Signup</div>
        </router-link>
        <router-link to="/forgot_pass">
            <div class="click">Forgot Password?</div>
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .login {
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        width: 200px;
        background-color: white;
        overflow: hidden;
    }
    .click {
        padding: 15px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
    }
</style>