<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Delete Art?</div>
            <div class="text">
                Are you sure that you want to delete this art ({{ art.title }})
            </div>
            <div class="bottom">
                <div class="btn" @click="close()">Cancel</div>

                <loading :height="'38px'" v-if="loading"/>
                <div class="btn" v-else @click="remove_art()">Delete</div>
            </div>
        </div>
    </div>
    
</template>

<script>
import loading from '../../loading.vue'

export default {
    components: {
        loading
    },
    props: ['art'],
    data() {
        return {
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        remove_art() {
            this.loading = true
            let form = new FormData()
            form.append('art_id', this.art.art_id)

            this.$http.post('/admin/art/delete', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.deleted()
                    setTimeout(() => {
                        this.$router.go()
                    }, 2000);
                }
            })
        }
    },
    notifications: {
        deleted: {
            type: 'success',
            title: '',
            message: 'Art deleted!!',
        }
    }
}
</script>

<style scoped>
    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        max-height: 80vh;
        overflow: auto;
        border-radius: 5px;
        padding: 20px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        margin-bottom: 20px;
    }


    .bottom {
        margin-top: 20px;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .btn {
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        background-color: var(--main);
        cursor: pointer;
        color: white;
        font-size: 14px;
        font-weight: 500;
    }
</style>