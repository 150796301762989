<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 96 960 960">
            <path d="M510 486V216h330v270H510ZM120 606V216h330v390H120Zm390 330V546h330v390H510Zm-390 
            0V666h330v270H120Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>