<template>
    <div class="walletconnect">
        <headerlayout class="h"/>

        <div class="main">
            <div class="title">Select A Wallet</div>

            <div class="wallet" @click="show_wallet('Metamask')">
                <div class="wallet-logo">
                    <img src="../assets/images/metamask.jpg"/>
                </div>
                <div>
                    <div class="wallet-name">Metamask</div>
                    <div class="wallet-url">metamask.io</div>
                </div>
            </div>
            <div class="wallet" @click="show_wallet('Trust Wallet')">
                <div class="wallet-logo">
                    <img src="../assets/images/trust_wallet.jpg"/>
                </div>
                <div>
                    <div class="wallet-name">Trust Wallet</div>
                    <div class="wallet-url">trustwallet.com</div>
                </div>
            </div>
            <div class="wallet" @click="show_wallet('Coinbase')">
                <div class="wallet-logo">
                    <img src="../assets/images/coinbase.png"/>
                </div>
                <div>
                    <div class="wallet-name">Coinbase</div>
                    <div class="wallet-url">coinbase.com</div>
                </div>
            </div>
            <div class="wallet" @click="show_wallet('Wallet Connect')">
                <div class="wallet-logo">
                    <img src="../assets/images/walle.jpg"/>
                </div>
                <div>
                    <div class="wallet-name">Wallet Connect</div>
                    <div class="wallet-url">wallettconnect.com</div>
                </div>
            </div>

        </div>

        <wallet_connect v-if="is_wallet" :wallet="wallet" @close="is_wallet=false"/>
    </div>
</template>

<script>
import headerlayout from '../components/util/header.vue'
import wallet_connect from '../components/popup/wallet_connect.vue'

export default {
    components: {
        headerlayout, wallet_connect
    },
    data() {
        return {
            is_wallet: false,
            wallet: ''
        }
    },
    mounted() {
        
        if (!this.$root.$data.iswalletconnect) {
            this.$router.push('/')
        }
    },
    methods: {
        show_wallet(wallet) {
            this.wallet = wallet
            this.is_wallet = true
        }
    }
}
</script>

<style scoped>
.walletconnect {
    /* padding-bottom: 30px; */
    position: relative;
    /* background-color: #f1f1f3; */
}
.h {
    position: sticky;
    top: 0px;
    z-index: 3;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

    .main {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
    }

    .wallet {
        margin: 0 auto;
        margin-top: 20px;
        background-color: white;

        max-width: 400px;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        padding: 15px;
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
    }
    .wallet-logo {
        width: 70px;
        height: 70px;

        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .wallet-logo img {
        width: 70px;
    }
    .wallet-name {
        font-weight: 700;
        /* font-size: 18px; */
    }
    .wallet-url {
        font-size: 14px;
        color: gray;
        margin-top: 5px;
    }
</style>