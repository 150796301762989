<template>
    <div class="menu">
        <router-link to="/admin">
            <div class="item" :class="[c == 'sales' ? 'a' : '']"> Sales </div>
        </router-link>

        <router-link to="/admin/users">
            <div class="item" :class="[c == 'users' ? 'a' : '']">Users</div>
        </router-link>

        <router-link to="/admin/arts">
            <div class="item" :class="[c == 'arts' ? 'a' : '']">Arts</div>
        </router-link>
    </div>
</template>

<script>
export default {
    computed: {
        c() {
            return this.$route.name
        }
    }
}
</script>

<style scoped>

    .menu {
        padding: 20px;
        border-right: 2px solid var(--main);
    }
    .item {
        padding: 15px;
        border-radius: 5px;

        display: flex;
        /* align-items: center; */
        gap: 10px;

        border: 1px solid #ddd;
        margin-bottom: 20px;
        cursor: pointer;
        fill: #555;
        font-weight: 600;
        font-size: 14px;
    }
    .a {
        background-color: var(--main);
        color: white;
        fill: white;
        border-color: var(--main);
    }
</style>