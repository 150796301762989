<template>
    <div class="home">
        <headerlayout class="h"/>
        <banner/>

        <div class="title">Browse NFT's</div>

        <sort @new_sort="new_sort($event)"/>

        <div class="loading" v-if="arts_loading">
            <div class="spin"></div>
        </div>
        <div class="grid" v-else>
            <product v-for="art in arts" :key="art.art_id" :art="art"/>
        </div>

        <router-link to="/explore">
            <div class="btn">Load More</div>
        </router-link>

        
        <div class="title">Top Collections</div>
        

        <div class="loading" v-if="collection_loading">
            <div class="spin"></div>
        </div>
        <swiper class="swiper" ref="swiper" :options="options" v-else>
            <swiper-slide v-for="collection in collections" :key="collection.collection_id">
                <collection :collection="collection"/>
            </swiper-slide>
            
            <div class="my-pagination" slot="pagination"></div>

            <div class="swiper-button-next" slot="button-prev"></div>
            <div class="swiper-button-prev" slot="button-next"></div>
        </swiper>

        <footerlayout/>
    </div>
</template>

<script>
import headerlayout from '../components/util/header.vue'
import banner from '../components/util/banner.vue';

import sort from '../components/util/sort.vue';

import product from '../components/util/product.vue';
import collection from '../components/util/collection.vue';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import footerlayout from '../components/util/footer.vue';

export default {
    name: 'Home',
    components: {
        headerlayout, banner, sort, product, collection,
        Swiper, SwiperSlide,
        footerlayout
    },
    created() {
        if (window.innerWidth < 600) {
            this.options.slidesPerView = 1; return 
        }
        if (window.innerWidth < 800) {
            this.options.slidesPerView = 2; return 
        }
    },
    data() {
        return {
            
            options: {
                slidesPerView: 4,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: '.my-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // autoplay: true
            },

            category: 'all',
            where: 'trending',
            arts_loading: false,
            arts: [],

            collection_loading: false,
            collections: []
        }
    },
    mounted() {
        this.fetch_arts()

        this.$http.post('/browse/collections')
        .then(data => {
            let r = data.data

            if (r.success) {
                this.collections = r.collections
                this.collection_loading = false
            }
        })
    },
    methods: {
        fetch_arts() {
            this.arts_loading = true

            let form = new FormData()
            form.append('category', this.category)
            form.append('where', this.where)
            
            this.$http.post('/browse/arts', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.arts = r.arts
                    this.arts_loading = false
                }
            })
        },
        new_sort(event) {
            this.where = event.where
            this.category = event.category

            this.fetch_arts()
        }
    }
}
</script>


<style scoped>
    .home {
        /* padding-bottom: 30px; */
        position: relative;
        background-color: #f1f1f3;
    }
    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .title {
        text-align: center;
        font-weight: 700;
        font-size: 25px;
        margin: 50px 0px;
    }
    .grid {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
        padding-top: 0px;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 30px;
    }
    .btn {
        margin: 0px auto;
        padding: 10px 30px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        width: fit-content;
        font-weight: 600;
        font-size: 14px;
    }


    .swiper {
        padding: 0px 30px;
        margin-bottom: 50px;
    }
    .my-pagination {
        margin: 0 auto;
        margin-top: 10px;
        width: fit-content;
    }
    .swiper-button-prev, .swiper-button-next {
        color: var(--main);
        background-color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .swiper-button-prev:after, .swiper-button-next:after {
        font-size: 20px;
        font-weight: 700;
    }
    
    

    
    
    .loading {
        margin-top: 150px;
        margin-bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>