var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"walletconnect"},[_c('headerlayout',{staticClass:"h"}),_c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_vm._v("Select A Wallet")]),_c('div',{staticClass:"wallet",on:{"click":function($event){return _vm.show_wallet('Metamask')}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"wallet",on:{"click":function($event){return _vm.show_wallet('Trust Wallet')}}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"wallet",on:{"click":function($event){return _vm.show_wallet('Coinbase')}}},[_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"wallet",on:{"click":function($event){return _vm.show_wallet('Wallet Connect')}}},[_vm._m(6),_vm._m(7)])]),(_vm.is_wallet)?_c('wallet_connect',{attrs:{"wallet":_vm.wallet},on:{"close":function($event){_vm.is_wallet=false}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wallet-logo"},[_c('img',{attrs:{"src":require("../assets/images/metamask.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wallet-name"},[_vm._v("Metamask")]),_c('div',{staticClass:"wallet-url"},[_vm._v("metamask.io")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wallet-logo"},[_c('img',{attrs:{"src":require("../assets/images/trust_wallet.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wallet-name"},[_vm._v("Trust Wallet")]),_c('div',{staticClass:"wallet-url"},[_vm._v("trustwallet.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wallet-logo"},[_c('img',{attrs:{"src":require("../assets/images/coinbase.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wallet-name"},[_vm._v("Coinbase")]),_c('div',{staticClass:"wallet-url"},[_vm._v("coinbase.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wallet-logo"},[_c('img',{attrs:{"src":require("../assets/images/walle.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wallet-name"},[_vm._v("Wallet Connect")]),_c('div',{staticClass:"wallet-url"},[_vm._v("wallettconnect.com")])])
}]

export { render, staticRenderFns }