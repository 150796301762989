<template>
    <div>
        <headerlayout class="h"/>

        <div class="main">
            <div class="title">About Us</div>
            <div class="text">
                We are a team of artists, developers, and entrepreneurs who are passionate about the 
                potential of NFTs to change the world. We believe that NFTs can be used to create 
                a more equitable and accessible art world, to empower creators to own and control 
                their work, and to build new communities around shared interests.
            </div>
            <div class="text">
                Our mission is to create a platform that makes it easy for anyone to create, buy, 
                sell, and trade NFTs. We believe that everyone should have the opportunity to 
                participate in this new and exciting technology, and we are committed to making our 
                platform as user-friendly and accessible as possible.
            </div>

            
            <div class="title">Our Team</div>
            <div class="text">
                Our team is made up of experienced professionals from a variety of backgrounds, 
                including art, technology, and business. We are all united by our passion for NFTs 
                and our belief in their potential to change the world.
            </div>

            <div class="title">Our Vision</div>
            <div class="text">
                We envision a world where NFTs are used to create a more equitable and accessible art 
                world, to empower creators to own and control their work, and to build new communities 
                around shared interests. We believe that NFTs have the potential to revolutionize the 
                way we think about art, ownership, and community, and we are committed to making that 
                vision a reality.
            </div>
        </div>

        <footerlayout/>
    </div>
    
</template>

<script>
import headerlayout from '../components/util/header.vue'

import footerlayout from '../components/util/footer.vue';

export default {
    components: {
        headerlayout, footerlayout
    },
    mounted() {
        window.scroll(0, 0)
    }
}
</script>

<style scoped>
    
    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    
    .main {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
    .text {
        margin-bottom: 30px;
        margin-top: 10px;
        line-height: 25px;
    }
</style>