<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Change Wallet Address</div>
            <div class="main">
                <div class="input">
                    <div class="label">Wallet Address</div>
                    <input type="text" placeholder="Enter wallet address" v-model="address">
                </div>

                <loading :height="'38px'" v-if="loading"/>
                <div class="btn" v-else @click="update()">Update</div>
            </div>
        </div>
    </div>
</template>

<script>
import loading from '../../loading.vue'

export default {
    components: {
        loading
    },
    props: ['wallet', 'user_id'],
    mounted() {
        this.address = this.wallet
    },
    data() {
        return {
            address: '',
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        update() {
            this.loading = true

            let form = new FormData()
            form.append('user_id', this.user_id)
            form.append('wallet', this.address)

            this.$http.post('/admin/change_user_wallet', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$emit('wallet_updated', this.address)
                    this.success()
                }

                this.loading = false
            })
        }
    },
    notifications: {
        success: {
            type: 'success',
            title: 'Updated',
            message: 'User wallet updated'
        }
    }
}
</script>

<style scoped>
    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        border-radius: 5px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        padding: 15px;
        border-bottom: 1px solid #ddd;
    }

    .main {
        padding: 20px;
    }
    .input {
        margin-bottom: 20px;
    }
    .label {
        font-size: 14px;
        margin-bottom: 2px;
        font-weight: 500;
    }
    input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }

    
    .btn {
        padding: 10px;
        border-radius: 5px;
        font-weight: 500;
        background-color: var(--main);
        font-size: 14px;
        color: white;
        text-align: center;
        cursor: pointer;
    }
</style>