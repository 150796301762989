<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 96 960 960">
            <path d="M180 976q-24 0-42-18t-18-42V386q0-24 18-42t42-18h110q0-79 53-134.5T475 136q79 0 137 
            55.5T670 326h110q24 0 42 18t18 42v530q0 24-18 42t-42 18H180Zm300-350q79 0 137-58t58-137h-60q0 
            55-40 95t-95 40q-55 0-95-40t-40-95h-60q0 79 58 137t137 58ZM350 326h260q0-55-37.5-92.5T480 
            196q-55 0-92.5 37.5T350 326Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>