<template>
    <div class="sort">
        <div class="f">
            <div class="t" :class="[where == 'trending' ? 't_a' : '']" 
                @click="where = 'trending'">Trending</div>
            
            <div class="t" :class="[where == 'hot' ? 't_a' : '']" 
                @click="where = 'hot'">Hot</div>

            <div class="t" :class="[where == 'new' ? 't_a' : '']" 
                @click="where = 'new'">New</div>
        </div>
        <div class="flex">

            <div class="c" :class="[category == 'all' ? 'a' : '']" 
                @click="category = 'all'">All</div>
                
            <div class="c" :class="[category == 'Top' ? 'a' : '']" 
                @click="category = 'Top'">Top Sellers</div>

            <div class="c" :class="[category == 'Digital Art' ? 'a' : '']" 
                @click="category = 'Digital Art'">Digital Art</div>

            <div class="c" :class="[category == 'Painting' ? 'a' : '']" 
                @click="category = 'Painting'">Painting</div>

            <div class="c" :class="[category == 'Drawing' ? 'a' : '']" 
                @click="category = 'Drawing'">Drawing</div>

            <div class="c" :class="[category == 'Photography' ? 'a' : '']" 
                @click="category = 'Photography'">Photography</div>

            <div class="c" :class="[category == 'Animation' ? 'a' : '']" 
                @click="category = 'Animation'">Animation</div>

            <div class="c" :class="[category == 'Graphics Design' ? 'a' : '']" 
                @click="category = 'Graphics Design'">Graphic Design</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            where: 'trending',
            category: 'all'
        }
    },
    watch: {
        where() {
            this.$emit('new_sort', {
                'where': this.where,
                'category': this.category
            })
        },
        category() {
            this.$emit('new_sort', {
                'where': this.where,
                'category': this.category
            })
        }
    },
    methods: {

    }
}
</script>

<style scoped>
    .sort {
        max-width: 1100px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .f {
        display: flex;
        gap: 20px;
    }
    .t {
        font-weight: 600;
        font-size: 18px;
        /* color: gray; */
        cursor: pointer;
    }
    .t_a {
        color: var(--main);
    }


    .flex {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        overflow: auto;
    }
    .c {
        padding: 8px 20px;
        font-size: 14px;
        /* border: 1px solid #ddd; */
        background-color: white;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        white-space: nowrap;
    }
    .a {
        background-color: var(--main);
        color: white;
        border-color: transparent;
    }

@media screen and (max-width: 1040px) {
    .sort {
        margin: 30px 20px;
    }
}
</style>