<template>
    <div class="collection">
        <div class="image">
            <img :src="collection.cover"/>
            <div class="owner">
                <div class="label">Owner:</div>
                <div class="value">{{collection.user.firstname}} {{ collection.user.lastname }}</div>
            </div>
        </div>
        <div class="main">
            <div class="name">{{collection.name}}</div>
            <div class="flex">
                <div class="f">
                    <div class="label">Created:</div>
                    <div class="value">{{ created }}</div>
                </div>
                <div class="f">
                    <div class="label">Arts:</div>
                    <div class="value">{{collection.arts}}</div>
                </div>
            </div>
            <router-link :to="'/collection/' + collection.url">
                <div class="btn">Browse Collection</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['collection'],
    data() {
        return {
            created: ''
        }
    },
    mounted() {
        this.created = moment(this.collection.created).fromNow()
    }
}
</script>

<style scoped>
    .collection {
        border-radius: 5px;
        overflow: hidden;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f3;
        height: 200px;
        width: 100%;
        overflow: hidden;

        position: relative;
    }
    .image img {
        width: 100%;
        display: block;
    }
    .owner {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        box-sizing: border-box;
        padding: 5px 15px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        font-size: 12px;
    }


    .main {
        padding: 15px;
    }
    .name {
        font-weight: 600;
        font-size: 18px;
    }
    .flex {
        display: flex;
        gap: 15px;
        margin-top: 2px;
    }
    .f {
        display: flex;
        gap: 5px;
        font-size: 12px;
    }
    .value {
        font-weight: 600;
    }

    .btn {
        padding: 8px;
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
    }
</style>