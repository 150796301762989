<template>
    <div class="product">

        
        <router-link :to="'/user/' + art.user.username">
            <div class="user">
                <div class="dp">
                    <img :src="art.user.dp"/>
                </div>
                <div>
                    <div class="name">
                        {{art.user.firstname}} {{art.user.lastname}}
                        <check :size="'15px'" v-if="art.user.vip"/>
                    </div>
                    <div class="small">
                        @{{ art.user.username }}
                    </div>
                </div>
            </div>
        </router-link>
        
        <router-link :to="'/art/' + art.art_id">
            <div class="title">{{art.title}}</div>

            <div class="image">
                <div class="category">
                    <div class="ca">{{ art.category }}</div>
                </div>

                <img :src="art.image"/>

                
                <div class="sold" v-if="art.sold">
                    Art sold
                </div>
            </div>
        
        </router-link>

        <div class="main">
            
            <div class="bottom">
                <div>
                    <div class="price">
                        <eth :size="'20px'" :style="{'height': '20px'}"/>
                        <div>{{art.price}}</div>
                        <div class="currency">{{art.currency}}</div>
                    </div>
                </div>
                <div class="buy" @click="buy()">Buy</div>
                <div class="icon" @click="add_to_cart()" :class="[in_cart ? 'added' : '']">
                    <cart_fill :size="'18px'" :style="{'height': '18px'}" v-if="in_cart"/>
                    <cart_add :size="'18px'" :style="{'height': '18px'}" v-else/>
                </div>
            </div>
            
            <!-- <router-link :to="'/art/' + art.art_id">
                <div class="description">
                    {{art.description}}
                </div>
            </router-link> -->

            <div class="options" v-if="reactions">
                <div class="opt">
                    <like :size="'18px'" :style="{'height': '18px'}"/>
                    <div class="no">{{art.likes}}</div>
                </div>
                <div class="opt">
                    <comment :size="'18px'" :style="{'height': '18px'}"/>
                    <div class="no">{{art.comments}}</div>
                </div>
                <div class="opt">
                    <save :size="'18px'" :style="{'height': '18px'}"/>
                    <div class="no">{{art.saves}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import check from '../../icons/check.vue';
import eth from '../../icons/eth.vue';
import cart_add from '../../icons/cart_add.vue';
import cart_fill from '../../icons/cart_fill.vue';
import like from '../../icons/like.vue'
import comment from '../../icons/comment.vue';
import save from '../../icons/save.vue'

export default {
    components: {
        check,
        eth, cart_add, cart_fill, like, comment, save
    },
    props: ['art'],
    data() {
        return {
            in_cart: false,

            reactions: false
        }
    },
    mounted() {
        let cart = this.$root.$data.cart

        for (let x of cart) {
            if (x.art_id == this.art.art_id) { 
                this.in_cart = true
            }
        }
    },
    computed: {
        cart() {
            return this.$root.$data.cart
        }
    },
    watch: {
        cart() {
            let cart = this.$root.$data.cart
            this.in_cart = false

            for (let x of cart) {
                if (x.art_id == this.art.art_id) { 
                    this.in_cart = true
                } else {
                    this.in_cart = false
                }
            }
        }
    },
    methods: {
        add_to_cart() {
            if (this.art.user.username == this.$root.$data.username) { this.owned(); return }
            if (this.art.sold) { this.sold(); return }
            if (this.in_cart) {
                let cart = this.$root.$data.cart
                let new_cart = []

                for (let x of cart) {
                    if (x.art_id == this.art.art_id) { continue }
                    new_cart.push(x)
                }

                this.$root.$data.cart = new_cart
                localStorage.setItem('cart', JSON.stringify(new_cart))

                this.in_cart = false

                this.removed()

            } else {

                this.$root.$data.cart.push(this.art)
                this.in_cart = true

                localStorage.setItem('cart', JSON.stringify(this.$root.$data.cart))

                this.added()
            }
        },
        buy() {
            if (this.art.user.username == this.$root.$data.username) { this.owned(); return }
            if (this.art.sold) { this.sold(); return }
            if (!this.$root.$data.login) { this.login(); return }

            if (!this.in_cart) {
                this.$root.$data.cart.push(this.art)
                this.in_cart = true

                localStorage.setItem('cart', JSON.stringify(this.$root.$data.cart))
                this.added()
            }
            this.$router.push('/checkout')
        }
    },
    notifications: {
        owned: {
            type: 'warn',
            title: '',
            message: "You can't buy your own art"
        },
        sold: {
            type: 'warn',
            title: '',
            message: 'This art has been sold'
        },
        added: {
            type: 'success',
            title: '',
            message: 'Art has been added to cart'
        },
        removed: {
            type: 'success',
            title: '',
            message: 'Art removed from cart'
        },
        login: {
            type: 'warn',
            title: '',
            message: 'You are required to login first'
        }
    }
}
</script>

<style scoped>
    .product {
        /* width: ; */
        border-radius: 5px;
        overflow: hidden;
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        background-color: white;
        /* border: 1px solid #ddd; */
    }
    
    .title {
        font-weight: 600;
        font-size: 16px;
        padding: 15px;
    }
    .image {
        width: 100%;
        overflow: hidden;

        position: relative;
    }
    .image img {
        width: 100%;
        display: block;
    }
    .category {
        position: absolute;
        top: 15px;
        left: 15px;

        display: flex;
        gap: 10px;
    }
    .ca {
        background-color: var(--main);
        color: white;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 2px;
    }

    
    .main {
        padding: 15px;
        border-top: none;
    }

    .bottom {
        width: 100%;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        gap: 15px;
        font-weight: 500;
        font-weight: 600;
        font-size: 14px;
    }
    .price {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .buy {
        font-size: 14px;
        font-weight: 500;
        border: 1px solid var(--main);
        color: var(--main);
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        margin-left: auto;
    }
    .icon {
        border: 1px solid var(--main);
        color: var(--main);
        fill: var(--main);
        border-radius: 5px;
        width: 40px;
        height: 30px;
        
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        box-sizing: border-box;
    }
    .added {
        background-color: var(--main);
        fill: white;
    }




    .description {
        font-size: 14px;
        margin-top: 10px;
    }

    .options {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
        /* margin-bottom: 10px; */
    }
    .opt {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        fill: #333;
        background-color: var(--trans);
        padding: 8px 20px;
        border-radius: 5px;
    }
    .no {
        font-size: 12px;
        font-weight: 600;
    }


    .user {
        padding: 10px 15px;
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        gap: 15px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
    }
    .dp {
        width: 30px;
        height: 30px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .dp img {
        width: 40px;
    }

    .name {
        font-weight: 600;
        font-size: 12px;
        
        display: flex;
        gap: 5px;
        fill: var(--main);
    }
    .small {
        font-weight: 500;
        color: gray;
        font-size: 10px;
    }
    .sold {
        background-color: var(--main);
        color: white;
        text-align: center;
        padding: 3px;
        font-weight: 500;
        font-size: 14px;
    }
</style>