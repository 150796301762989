<template>
    <div class="signup">
        <headerlayout class="h"/>

        <div class="main">
            <div class="box">
                <div class="title">Sign up</div>
                <div class="input">
                    <div class="label" v-if="username !== ''">Username</div>
                    <input type="text" placeholder="Enter your username" v-model="username">
                </div>
                <div class="input">
                    <div class="label" v-if="email !== ''">Email</div>
                    <input type="text" placeholder="Enter your email" v-model="email">
                </div>
                <div class="input">
                    <div class="label">Annual Earnings</div>
                    <select v-model="earnings">
                        <option value="" selected disabled>Select your annual earnings</option>
                        <option value="$1000 - $5000">$1000 - $5000</option>
                        <option value="$5000 - $30000">$5000 - $30000</option>
                        <option value="$30000 - $100000">$30000 - $100000</option>
                        <option value="$100000 - Above">$100000 - Above</option>
                    </select>
                </div>
                <div class="input">
                    <div class="label" v-if="password !== ''">Password</div>
                    <input type="password" placeholder="Enter your password" v-model="password">
                </div>
                <div class="input">
                    <div class="label" v-if="password2 !== ''">Reapeat Password</div>
                    <input type="password" placeholder="Reapeat your password" v-model="password2">
                </div>

                <loading v-if="loading" :height="'39px'"/>
                <div class="btn" v-else @click="signup()">Sign Up</div>

                <router-link to="/login">
                    <div class="text">Already have an account? <span>Login</span></div>
                </router-link>
            </div>
        </div>

        <footerlayout/>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitnfts')

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

import loading from '../components/loading.vue'

export default {

    components: {
        headerlayout, footerlayout,
        loading
    },
    data() {
        return {
            email: '',
            username: '',
            earnings: '',
            password: '',
            password2: '',

            loading: false
        }
    },
    mounted() {
        window.scroll(0, 0)

        db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$root.$data.login = false
            } else {
                this.$router.push('/')
            }
        })
    },
    methods: {
        signup() {
            if (this.email == '' || this.username == '' || this.earnings == '' || this.password == '') {
                this.empty(); return
            }
            if (this.password != this.password2) {
                this.password_mismatch(); return 
            }

            this.loading = true

            let form = new FormData()
            form.append('email', this.email)
            form.append('username', this.username)
            form.append('earnings', this.earnings)
            form.append('password', this.password)

            this.$http.post('/signup', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.success()
                    
                    // this.$crisp.push(["do", "chat:show"])

                    db.collection('auth').add({
                        'id': 1,
                        'key': r.session,
                        'user_id': r.user_id,
                        'firstname': '',
                        'lastname': '',
                        'username': this.username,
                        'email': this.email,
                        'email_verified': false
                    }).then( () => {
                        
                        this.$root.$data.login = true
                        this.$http.defaults.headers.common['Auth'] = r.session

                        this.$root.$data.email = this.email
                        this.$router.push('/verify')
                    })
                }
                else {
                    if (r.invalid) {
                        this.invalid()
                    }
                    if (r.username) {
                        this.username_err()
                    }
                    if (r.email) {
                        this.email_err()
                    }
                }

                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Some fields are empty'
        },
        password_mismatch: {
            type: 'warn',
            title: '',
            message: 'Password Mismatch'
        },

        invalid: {
            type: 'warn',
            title: 'Invalid',
            message: 'The email you entered is invalid'
        },
        username_err: {
            type: 'warn',
            title: '',
            message: 'The username you entered has been taken'
        },
        email_err: {
            type: 'warn',
            title: '',
            message: 'This email has already been registered'
        },

        success: {
            type: 'success',
            title: 'Success',
            message: 'Your account has been created'
        }
    }
}
</script>

<style scoped>


    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .main {
        height: 100vh;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f3;
    }

    .box {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        width: 400px;
        padding: 25px;
        border-radius: 10px;
    }
    .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 30px;
        text-align: center;
    }
    .input {
        margin-bottom: 30px;
        outline: none;
        position: relative;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        background-color: white;
        font-weight: 600;
        padding: 0px 5px;
        font-size: 14px;
    }
    input, select{
        padding: 15px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .btn {
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        font-weight: 600;
    }

    .text {
        font-weight: 500;
        font-size: 14px;
        margin-top: 30px;
        text-align: center;
    }
    span {
        color: var(--main);
    }
</style>