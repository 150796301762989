<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Remove ETH</div>
            <div class="main">
                <div class="input">
                    <div class="label">Amount</div>
                    <input type="number" placeholder="Enter ETH amount" v-model="amount">
                </div>
                <div class="input">
                    <div class="label">Reason</div>
                    <input type="text" placeholder="Enter transaction purpose" v-model="reason">
                </div>
                <div class="input">
                    <div class="label">Notify User</div>
                    <div class="flex">
                        <input type="checkbox" v-model="notify">
                        <div class="text">
                            By notifying user, this transactions will appear on the user transaction list
                        </div>
                    </div>
                </div>
                <loading :height="'38px'" v-if="loading"/>
                <div class="btn" v-else @click="remove()">Remove</div>
            </div>
        </div>
    </div>

</template>

<script>
import loading from '../../loading.vue'

export default {
    components: {
        loading
    },
    props: ['user_id'],
    data() {
        return {
            loading: false,

            amount: '',
            reason: '',
            notify: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        remove() {
            if (this.amount == '' || this.reason == '') { this.empty(); return }

            this.loading = true

            let form = new FormData()
            form.append('user_id', this.user_id)
            form.append('sign', '-')
            form.append('amount', this.amount)
            form.append('reason', this.reason)
            form.append('notify', this.notify ? '1' : '0')

            this.$http.post('/admin/transaction/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.removed(); 
                    this.$emit('removed', parseFloat(this.amount))
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter transaction amount and reason'
        },
        removed: {
            type: 'success',
            title: '',
            message: 'ETH has been remove'
        }
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        border-radius: 5px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        padding: 15px;
        border-bottom: 1px solid #ddd;
    }

    .main {
        padding: 20px;
    }
    .input {
        margin-bottom: 20px;
    }
    .label {
        font-size: 14px;
        margin-bottom: 2px;
        font-weight: 500;
    }
    input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }

    .flex {
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        gap: 20px;
    }
    .text {
        font-size: 12px;
    }
    
    .btn {
        padding: 10px;
        border-radius: 5px;
        font-weight: 500;
        background-color: var(--main);
        font-size: 14px;
        color: white;
        text-align: center;
        cursor: pointer;
    }
</style>