<template>
    <div class="order">
        
        <div class="flex" @click="show_main = !show_main">

            <div class="icon" :class="[show_main ? 'rot' : '']">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="fle">
                <div class="label">Art:</div>
                <div class="value">{{ order.art.title }}</div>
            </div>
            <div class="fle">
                <div class="label">Status:</div>
                <div class="value">{{ order.status }}</div>
            </div>
        </div>
        <div v-if="show_main">
            <div class="main">
                <div class="image">
                    <img :src="order.art.image"/>
                </div>
                <div class="info">
                    <div class="title">{{ order.art.title }}</div>
                    <div class="f">
                        <div class="label">Price:</div>
                        <div class="value">{{ order.art.price }} {{ order.art.currency }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Status:</div>
                        <div class="value">{{ order.status }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Order Id:</div>
                        <div class="value">{{ order.order_id }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Messages:</div>
                        <div class="value">{{ order.messages }}</div>
                    </div>
                </div>
            </div>
            <div class="foot">
                <div class="text">
                    You can use the message option to send us a message about this order
                </div>
                <!-- <div class="btn" @click="show_message = true">Message</div> -->
            </div>
        </div>

        <message :order_id="order.order_id" v-if="show_message" 
            @close="show_message = false"/>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow.vue';
import message from './message.vue'

export default {
    components: {
        arrow, message
    },
    props: ['order'],
    data() {
        return {
            show_main: true,
            show_message: false
        }
    }
}
</script>

<style scoped>

    .order {
        background-color: white;
        margin-top: 20px;
        border-radius: 5px;
        overflow: hidden;
    }
    .flex {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 15px;
        overflow: hidden;
    }
    .fle {
        display: flex;
        gap: 5px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .icon {
        background-color: var(--main);
        fill: white;
        height: 40px;
        width: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg);
    }

    .main {
        border-top: 1px solid #ddd;
        display: grid;
        grid-template-columns: 30% 70%;
        height: 181px;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .image img {
        width: 100%;
        display: block;
    }
    .info {
        padding: 15px;
    }
    .f {
        display: flex;
        margin-top: 15px;
        font-size: 14px;
        gap: 10px;
    }
    .title {
        font-weight: 600;
    }


    .foot {
        padding: 20px;
        border-top: 1px solid #ddd;
    }
    .foot .text {
        font-size: 12px;
        /* margin-bottom: 10px; */
    }
    .btn {
        font-size: 14px;
        font-weight: 500;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        cursor: pointer;
    }
</style>