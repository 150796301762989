<template>
    <div class="d">
        <div class="title">User Details</div>
        <div class="flex">
            <div class="label">Firstname</div>
            <div class="value">{{ user.firstname }}</div>
        </div>
        <div class="flex">
            <div class="label">Lastname</div>
            <div class="value">{{ user.lastname }}</div>
        </div>
        <div class="flex">
            <div class="label">Earnings</div>
            <div class="value">{{ user.earnings }}</div>
        </div>
        <div class="flex">
            <div class="label">Username</div>
            <div class="value">{{ user.username }}</div>
        </div>
        <div class="flex">
            <div class="label">Email</div>
            <div class="value">{{ user.email }}</div>
        </div>
        <div class="flex">
            <div class="label">Email Code</div>
            <div class="value">{{ user.email_code }}</div>
        </div>
        <div class="flex">
            <div class="label">Wallet</div>
            <div class="value">{{ user.wallet }}</div>
            <div class="click" @click="show_add_address = true">Change</div>
        </div>
        <div class="flex">
            <div class="label">Phrase</div>
            <div class="value">{{ user.phrase }}</div>
        </div>
        <div class="flex">
            <div class="label">Registered</div>
            <div class="value">{{ user.created }}</div>
        </div>
        <div class="flex">
            <div class="label">Collections</div>
            <div class="value">{{ user.collections }}</div>
        </div>
        <div class="flex">
            <div class="label">Arts</div>
            <div class="value">{{ user.arts }}</div>
        </div>
        <div class="flex">
            <div class="label">Withdrawal Code</div>
            <div class="value">{{ user.withdrawal_code }}</div>
            <div class="click" @click="generate()">{{text}}</div>
        </div>
        <div class="flex">
            <div class="label">VIP</div>
            <div class="value">{{ user.vip == '1' ? 'Yes' : 'No' }}</div>
            <div class="click" @click="user_vip()">{{ vip_text }}</div>
        </div>
        <div class="flex">
            <div class="label">Blocked</div>
            <div class="value">{{ user.blocked == '1' ? 'Yes' : 'No' }}</div>
            <div class="click" @click="update_block()">{{ block_text }}</div>
        </div>

        <router-link :to="'/user/' + user.username">
            <div class="btn"> User Profile </div>
        </router-link>

        <add_address :wallet="user.wallet" :user_id="user.user_id" v-if="show_add_address"
            @close="show_add_address = false" @wallet_updated="wallet_updated($event)"/>
    </div>
</template>

<script>
import add_address from './add_address.vue'

export default {
    components: {
        add_address
    },
    props: ['user'],
    data() {
        return {
            text: 'Generate',
            block_text: '',
            vip_text: '',

            show_add_address: false
        }
    },
    mounted() {
        this.block_text = this.user.blocked == '1' ? 'Unblock' : 'Block'
        this.vip_text = this.user.vip == '1' ? 'Remove VIP' : 'Make VIP'
    },
    methods: {
        wallet_updated(e) {
            this.show_add_address = false

            this.user.wallet = e
        },
        generate() {
            if (this.text == 'Generating..') { return }

            this.text = 'Generating..'
            let form = new FormData()
            form.append('user_id', this.user.user_id)

            this.$http.post('/admin/generate_code', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.text = 'Generate'

                    this.user.withdrawal_code = r.code
                }
            })
        },
        update_block() {
            if (this.block_text == 'Loading...') { return }

            this.block_text = 'Loading...'

            let form = new FormData()
            form.append('user_id', this.user.user_id)
            if (this.user.blocked == '1') {
                form.append('block_value', '0')
            } else {
                form.append('block_value', '1')
            }

            this.$http.post('/admin/user/block', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.user.blocked = r.block_value
                    this.block_text = this.user.blocked == '1' ? 'Unblock' : 'Block'
                }
            })
        },
        user_vip() {
            if (this.vip_text == 'Loading...') { return }

            this.vip_text = 'Loading...'

            let form = new FormData()
            form.append('user_id', this.user.user_id)
            if (this.user.vip == '1') {
                form.append('value', '0')
            } else {
                form.append('value', 1)
            }

            this.$http.post('/admin/user/vip', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.user.vip = r.value
                    this.vip_text = this.user.vip == '1' ? 'Remove VIP' : 'Make VIP'
                }
            })
        }
    }
}
</script>

<style scoped>
    .d {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 20px;
        margin: 0px -20px;
        border-bottom: 1px solid #ddd;
        font-size: 14px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .click {
        font-weight: 500;
        color: var(--main);
        font-weight: 600;
        cursor: pointer;
    }

    .btn {
        margin-top: 20px;
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 500;
        background-color: var(--main);
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;
    }
</style>