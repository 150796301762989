<template>
    <div class="banner">
        <div class="image">
            <img :src="user.cover"/>
        </div>
        <div class="main">
            <div class="dp">
                <img :src="user.dp" alt="">
            </div>
            <div class="name">
                {{user.firstname}} {{user.lastname}}
                <check :size="'15px'" v-if="user.vip"/>
            </div>
            <div class="flex">
                <div>@{{ user.username }}</div>
                <div>Joined: {{joined}}</div>
            </div>
        </div>
        <div class="btn" @click="show_edit = true">Edit</div>

        <edit :cover="user.cover" :dp="user.dp" v-if="show_edit" @close="show_edit = false"/>
    </div>
</template>

<script>
import moment from 'moment'

import check from '../../../icons/check.vue'

import edit from './edit.vue'

export default {
    props: ['user'],
    components: {
        check,
        edit
    },
    data() {
        return {
            joined: '',
            show_edit: false
        }
    },
    mounted() {
        this.joined = moment(this.user.created).format('Do MMM YYYY')
    }
}
</script>

<style scoped>
    .banner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 5px;
        overflow: hidden;
    }
    .image {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .image img {
        width: 100%;
    }

    .main {
        text-align: center;
        margin: 50px 0px;
        position: relative;
        z-index: 2;
    }
    .dp {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .dp img {
        width: 100px;
    }
    .name {
        font-weight: 600;
        font-size: 20px;
        margin-top: 10px;
        color: white;
        
        display: flex;
        justify-content: center;
        gap: 5px;
        fill: white;
    }
    .flex {
        display: flex;
        gap: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #d1d1d1;
    }

    .btn {
        background-color: white;
        color: var(--main);
        font-weight: 500;
        padding: 5px 30px;
        border-radius: 5px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 14px;
    }
</style>