<template>
    <div class="dashboard">
        
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <banner :user="user"/>
            <div class="grid">
                <collections :no="user.collections" :collections="collections"/>
                <art :no="user.arts" :arts="arts"/>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '../../components/dashboard/dash/banner.vue';
import collections from '../../components/dashboard/dash/collections.vue';
import art from '../../components/dashboard/dash/art.vue';

export default {
    components: {
        banner, collections, art
    },
    data() {
        return {
            loading: true,
            user: {},
            collections: [],
            arts: []
        }
    },
    mounted() {
        this.$http.post('/user/dashboard')
        .then(data => {
            let r = data.data

            if (r.success) {
                this.user = r.user
                this.collections = r.collections
                this.arts = r.arts

                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
    .dashboard {
        padding: 20px;
        background-color: #f1f1f3;
    }

    .grid {
        margin-top: 20px;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }


    
    .loading {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>