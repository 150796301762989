<template>
    <div class="transaction">
        
        <div class="flex" @click="show_main = !show_main">
            <div class="icon" :class="[show_main ? 'rot' : '']">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="fle">
                <div class="label">Amount:</div>
                <div class="value">{{ tx.sign }} {{ tx.amount }}</div>
            </div>
            <div class="fle">
                <div class="label">Date:</div>
                <div class="value">{{ date }}</div>
            </div>
        </div>
        <div class="main" v-if="show_main">
            <div class="title">Transaction Info</div>
            <div class="f">
                <div class="l">Date:</div>
                <div class="v">{{ tx.date }}</div>
            </div>
            <div class="f">
                <div class="l">Reason:</div>
                <div class="v">{{ tx.reason }}</div>
            </div>
            <div class="f">
                <div class="l">Notify:</div>
                <div class="v">{{ tx.notify == '1' ? 'Yes' : 'No' }}</div>
            </div>
            <div class="f last">
                <div class="l">Amount:</div>
                <div class="v">{{ tx.sign }} {{ tx.amount }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow.vue';
import moment from 'moment';
export default {
    props: ['tx'],
    components: {
        arrow
    },
    data() {
        return {
            date: moment(this.tx.date).fromNow(),
            show_main: false
        }
    }
}
</script>

<style scoped>

    .transaction {
        background-color: white;
        margin-top: 20px;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #ddd;
    }
    .flex {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 15px;
        overflow: hidden;
    }
    .fle {
        display: flex;
        gap: 5px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .click {
        font-weight: 600;
        color: var(--main);
    }
    .icon {
        background-color: var(--main);
        fill: white;
        height: 40px;
        width: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg);
    }


    .main {
        border-top: 1px solid #ddd;
    }
    .title {
        padding: 20px;
        padding-bottom: 10px;
        font-weight: 500;
    }
    .f {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        font-size: 14px;
        padding: 8px 20px;
    }
    .last {
        border-bottom: none;
    }
    .v {
        font-weight: 500;
    }
</style>