<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Transactions</div>
            <div class="main">

                <div class="loading" v-if="loading">
                    <div class="spin"></div>
                </div>
                <div class="empty" v-if="!loading && transactions.length == 0">
                    You have not made any transactions
                </div>
                <div v-for="tx in transactions" :key="tx.id" class="transaction">
                    <div>{{ tx.sign }} {{ tx.amount }} ETH</div>
                    <div>{{ tx.reason }}</div>
                    <!-- <div>{{ tx.date }}</div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            transactions: []
        }
    },
    mounted() {
        this.$http.post('/user/transactions')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.transactions = r.transactions
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        max-height: 80vh;
        overflow: auto;
        border-radius: 5px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        padding: 20px;
    }
    .main {
        border-top: 1px solid #ddd;
    }

    .transaction {
        display: flex;
        padding: 10px 20px;
        font-size: 14px;
        display: flex;
        gap: 20px;
        border-bottom: 1px solid #ddd;
    }
    

    .empty {
        font-size: 14px;
        text-align: center;
        padding: 20px;
    }
    .loading {
        margin: 30px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>