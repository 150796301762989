<template>
    <div class="box">
        <div class="title">Arts ({{no}})</div>
        <!-- <div class="no">23</div> -->

        <div class="empty" v-if="arts.length == 0">
            You have not added any art to your collections
        </div>

        <div class="arts" v-else>
            <div class="art" v-for="art in arts" :key="art.art_id">
                <img :src="art.image"/>
                <div class="info">
                    <div class="t">{{art.name}}</div>
                    <div class="f">
                        <div class="label">Price:</div>
                        <div class="value">{{art.price}} {{art.currency}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Collection:</div>
                        <div class="value">{{art.collection.name}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Posted:</div>
                        <div class="value">{{art.created}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">
            <router-link to="/dashboard/collections">
                <div class="btn">View All</div>
            </router-link>
            <router-link to="/dashboard/collections">
                <div class="btn">Add</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['arts', 'no'],
    mounted() {
        for(let i = 0; i < this.arts.length; i++) {
            this.arts[i].created = moment(this.arts[i].created).fromNow()
        }
    }
}
</script>

<style scoped>

    .box {
        background-color: white;
        border-radius: 5px;
        height: fit-content;
    }
    .title {
        padding: 20px;
        padding-bottom: 0px;
        font-size: 18px;
        font-weight: 600;
    }

    
    .empty {
        margin: 20px 0px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 50px 20px;
        text-align: center;
        font-size: 12px;
        color: gray;
    }

    .no {
        margin: 50px 0px;
        font-weight: 500;
        font-size: 25px;
        text-align: center;
    }

    .arts {
        padding: 20px;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .art {
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #ddd;
    }
    .art img {
        width: 100%;
    }
    .info {
        padding: 10px;
    }
    .t {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .f {
        display: flex;
        font-size: 12px;
        gap: 10px;
    }
    .value {
        font-weight: 500;
    }

    
    .grid {
        padding: 20px;
        padding-top: 0px;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .btn {
        padding: 10px;
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
    }


    @media screen and (max-width: 750px) {
        .arts {
            grid-template-columns: auto;
        }
    }
</style>