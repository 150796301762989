<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Edit Art</div>
            
            <div class="input">
                <div class="label">Title</div>
                <input type="text" placeholder="Enter art title" v-model="title">
            </div>
            <div class="input">
                <div class="label">Description</div>
                <textarea placeholder="Describe this art" v-model="description"></textarea>
            </div>
            <div class="input">
                <div class="label">About</div>
                <textarea placeholder="What's this art about?" v-model="about"></textarea>
            </div>
            <div class="input">
                <div class="label">Category</div>
                <select v-model="category">
                    <option value="" selected disabled>Select Category</option>
                    <option value="Digital Art">Digital Art</option>
                    <option value="Painting">Painting</option>
                    <option value="Drawing">Drawing</option>
                    <option value="Photography">Photography</option>
                    <option value="Animation">Animation</option>
                    <option value="Graphics Design">Graphics Design</option>
                </select>
            </div>
            <div class="input">
                <div class="label">Price</div>
                <div class="flex">
                    <input type="number" placeholder="Enter art price" v-model="price">
                    <select>
                        <option value="ETH" selected>ETH</option>
                    </select>
                </div>
            </div>

            <loading :height="'34px'" v-if="loading"/>
            <div class="btn" @click="save()" v-else>Save</div>
        </div>
    </div>
</template>

<script>
import loading from '../../loading.vue'

export default {
    components: {
        loading
    },
    props: ['art'],
    data() {
        return {
            title: '',
            description: '',
            about: '',
            category: '',
            price: '',
            loading: false
        }
    },
    mounted() {
        this.title = this.art.title
        this.description = this.art.description
        this.about = this.art.about
        this.category = this.art.category
        this.price = this.art.price
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        save() {
            
            if (this.title == '' || this.description == '' || this.about == '' || this.category == '' ||
                this.price == '') {
                    this.empty(); return
                }
                
            this.loading = true

            let form = new FormData()
            form.append('art_id', this.art.art_id)
            form.append('title', this.title)
            form.append('description', this.description)
            form.append('about', this.about)
            form.append('category', this.category)
            form.append('price', this.price)

            this.$http.post('/user/collection/art/edit', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.edited()
                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                }
            })
        }
    },
    notifications: {
        
        empty: {
            type: 'warn',
            title: '',
            message: 'Please make sure all field are not empty'
        },
        edited: {
            type: 'success',
            title: '',
            message: 'You art has been edited'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        max-height: 80vh;
        overflow: auto;
        border-radius: 5px;
        padding: 20px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        margin-bottom: 20px;
    }

    
    .upload {
        background-color: #f1f1f3;
        margin-bottom: 20px;

        /* height: 120px; */

        border-radius: 5px;
        overflow: hidden;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .remove {
        fill: var(--main);
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
    }
    .image img {
        width: 100%;
    }
    .uploader {
        height: 120px;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .upload_title {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
    }
    .upload_small {
        text-align: center;
        color: gray;
        font-size: 10px;
    }

    .input {
        margin-bottom: 20px;
        position: relative
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
    }
    input, textarea, select {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .flex {
        display: flex;
        gap: 20px;
    }
    .f {
        display: flex;
        justify-content: space-between;
        margin-bottom: -15px;
    }
    .up {
        margin-top: 20px;
    }
    .click {
        font-weight: 500;
        font-size: 14px;
        color: var(--main);
        cursor: pointer;
    }
    
    .btn {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }


</style>