<template>
    <div class="arts">
        <div class="title">Arts</div>
        
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <art v-for="art in arts" :key="art.id" :art="art"/>
        </div>
    </div>
</template>

<script>
import art from '../../components/admin/arts/art.vue'

export default {
    components: {
        art
    },
    data() {
        return {
            loading: true,
            arts: []
        }
    },
    mounted() {
        this.$http.post('/admin/arts')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.arts = r.arts
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>

    .arts {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
    }


    

    .loading {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>