<template>
    <div class="sales">
        <div class="title">
            Sales
        </div>
        

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <sale v-for="sale in sales" :sale="sale" :key="sale.sale_id"/>
        </div>
    </div>
</template>

<script>
import sale from '../../components/admin/sales/sale.vue'
export default {
    components: {
        sale
    },
    data() {
        return {
            sales: [],
            loading: true
        }
    },
    mounted() {
        this.$http.post('/admin/sales/all')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.sales = r.sales
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
    .sales {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
    }

    



    .loading {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>